export default {
  userProfile: {
    heading: "@:appLayout.menu.myProfile",
    settings: "Nastavení profilu",
    publicItemsHeading: "Zveřejněné předměty",
    breadcrumbs: {
      public: "Veřejný profil sběratele",
    },
    offeringFor: "Nabízím za",
    noContact: "Uživatel nemá vyplněné osobní údaje.",
    actions: {
      sendMessage: "@:views.itemDetail.actions.sendMessage",
    },
    share: {
      heading: "Sdílej svoji sbírku",
      toggleLabel: "Věřejný profil",
      textPublic: "Zkopíruj si odkaz na svůj profil a sdílej ho s ostatními sběrateli. Využít ho můžeš například v prodejních skupinách nebo jako prezentaci své sbírky.",
      textPrivate: "Pokud chceš svoji sbírku sdílet, musí být nejdříve ve stavu “<strong>veřejný</strong>”.",
      linkCopiedMsg: "Hurá! Odkaz na tvůj profil zkopírován do schránky. Sdílej svoji sbírku. :)",
      openPofileBtn: "Zobrazit veřejný profil",
    },
    noItems: "Uživatel nemá žádné předměty.",
    notFound: {
      doesNotExists: "sbírka neexistuje",
      heading: "Jak funguje Colectio",
      point1: "Měj svou sbírku na jednom místě",
      point2: "Sdílej své předměty a prodávej",
      point3: "Využívej aktuálních trendů a dat z trhu",
      point4: "Sleduj vývoj cen předmětů",
      point5: "Užij si magazín ze světa sběratelů",
      bottomBarText: "Chceš taky <br />svoji <strong>sbírku?</strong>",
      bottomBarCta: "Ano, chci!",
    },
    filter: {
      categoryPlaceholder: "Všechny kategorie",
    },
  },
};
