/**
 * Would be nice to rewrite Category.js to work with id and name only.
 * Then there will be no need for this class.
 **/

export class SimpleCategory {
  constructor(private id: number, private name: string) {}

  getId(): number {
    return this.id;
  }

  getName(): string {
    return this.name;
  }
}
