import { router } from "@/router";
import { createDashboardRouteLink } from "@/utilities/routerHelpers";

export function redirectAfterLogin() {
  const route = router.currentRoute;

  const urlRedirect = route.value.query.redirect_url;
  if (urlRedirect) {
    router.push(urlRedirect as string);
  } else {
    const dashboardRoute = createDashboardRouteLink();
    router.push(dashboardRoute);
  }
}
