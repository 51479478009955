import { CategoryId } from "@/types";
import { WatchParameter, Parameters as WatchParameters } from "../../database/watch";
import { Entity } from "../../Entity";
import { Parameter } from "./parameters";

export class Category extends Entity {
  constructor(
    public id: number, //
    public url: string,
    public name: string,
    public isSearchable: 0 | 1,
    public cover: string | undefined,
    public parameters: Parameter[] = []
  ) {
    super(id);
  }

  public async getParameters(): Promise<Parameter[]> {
    if (this.is(CategoryId.watch)) {
      throw new Error("DEV: Should not happen. For Watch category use getWatchParameters() method.");
    } else {
      return this.parameters;
    }
  }

  // Watch-specific method, remove when new universal storage will be created
  public async getWatchParameters(): Promise<WatchParameter[]> {
    if (this.is(CategoryId.watch)) {
      return await WatchParameters.get(false, false);
    } else {
      throw new Error("DEV: Should not happen. Use getWatchParameters() method only for Watch-category item.");
    }
  }

  public getName(): string {
    return this.name;
  }

  public getUrl(): string {
    return this.url;
  }

  public is(categoryId: number): boolean {
    return this.getId() === categoryId;
  }
}
