import { ImageHtmlSettings, ImageUrls, ImageVariants, RawImageData, SingleImageData, ImageSize } from "@/types";

const FallbackExtension = "jpeg";

export function createImage(data: RawImageData, alt = ""): Image {
  const img = new Image(data.id);
  img.setVariants(data.imageUrl);
  img.setName(alt);
  return img;
}

export class Image {
  private variants: ImageVariants = {};
  private name = "";

  constructor(public id: number) {}

  public getId(): number {
    return this.id;
  }

  public setVariants(variants: ImageVariants): void {
    this.variants = variants;
  }

  public setName(name: string): void {
    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public generateHtml(picture: ImageSize | ImageHtmlSettings): string {
    let sourcesHtml = "";
    let fallbackHtml = "";

    // We've received specific image size name.
    if (typeof picture === "string") {
      const size = picture;
      const pictures = this.variants[size];

      if (!pictures) {
        throw new Error("Requested non-existing image size called " + size + ".");
      }

      const images = convertToSingleImagesData(pictures);
      images.forEach((image) => {
        if (image.extension !== FallbackExtension) {
          sourcesHtml += createSourcesTags([image]);
        }
      });

      const fallbackImage = getFallbackImage(pictures);
      fallbackHtml = createImgTag(fallbackImage, this.getName());

      // We've received ImageHtmlSettings - <sources /> with media attributes.
    } else {
      picture.sources.forEach((source) => {
        const variant = this.variants[source.size];
        if (!variant) {
          throw new Error("Requested non-existing image size called " + source.size + ".");
        }

        const nonfallbackImages = convertToSingleImagesData(variant);
        sourcesHtml += createSourcesTags(nonfallbackImages, source.media);
      });

      const fallbackSize = this.variants[picture.fallbackSize];

      if (!fallbackSize) {
        throw new Error("Requested non-existing image size called " + picture.fallbackSize + ".");
      }

      const images = convertToSingleImagesData(fallbackSize);
      images.forEach((image) => {
        if (image.extension !== FallbackExtension) {
          sourcesHtml += createSourcesTags([image]);
        }
      });

      const fallbackImage = getFallbackImage(fallbackSize);
      fallbackHtml = createImgTag(fallbackImage, this.getName());
    }

    return `<picture>` + sourcesHtml + fallbackHtml + `</picture>`;
  }
}

function createSourcesTags(images: SingleImageData[], media?: string): string {
  return images
    .map((image) => {
      const mediaAttr = media ? `media="(${media})"` : "";
      return `<source srcset="${image.url}" type="image/${image.extension}" ${mediaAttr} />`;
    })
    .join("");
}

function createImgTag(image: SingleImageData, title: string): string {
  const escapedTitle = title.replaceAll(/\\|"/g, ""); // We don't want backslashes and quotes in attribute.
  return `<img src="${image.url}" alt="${escapedTitle}" title="${escapedTitle}" />`;
}

function getFallbackImage(urls: ImageUrls): SingleImageData {
  if (urls[FallbackExtension] === undefined) {
    throw new Error('Fallback format ("' + FallbackExtension + '") not found in available images.');
  }

  return {
    extension: FallbackExtension,
    url: urls[FallbackExtension],
  };
}

function convertToSingleImagesData(urls: ImageUrls): SingleImageData[] {
  const images: SingleImageData[] = [];

  for (const extension in urls) {
    images.push({
      extension: extension,
      url: urls[extension],
    });
  }

  return images;
}
