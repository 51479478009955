<template>
  <div class="icon" :style="inlineStyles">
    <FontAwesomeIconComponent v-if="isFontAwesomeIcon" :icon="(icon as FontAwesomeIcon)" />
    <component v-else :is="CustomIconComponents.get(icon as CustomIcon)" />
  </div>
</template>

<script lang="ts">
import { Component, computed, PropType, StyleValue } from "vue";
import { FontAwesomeIcon as FontAwesomeIconComponent } from "@fortawesome/vue-fontawesome";
import { IconDefinition as FontAwesomeIcon } from "@fortawesome/fontawesome-svg-core";
import { library } from "@fortawesome/fontawesome-svg-core";

export type TypeIcon = FontAwesomeIcon | CustomIcon;

/**
 * How do I add a new custom icon?
 *   1) Create file containing its SVG in ./icon/<my-new-icon>.vue
 *   2) Add icon into CustomIcon enum.
 *   3) Add icon into CustomIconComponents.
 */

export enum CustomIcon {}

export const CustomIconComponents = new Map<CustomIcon, Component>([
  // [CustomIcon.chatBubble, defineAsyncComponent(() => import("./icons/ChatBubble.vue"))], // Left here as an example
]);

export const iconProperty = {
  // Number === item from our CustomIcon enum, Object === font awesome icon definition
  type: [Object, Number] as PropType<TypeIcon>,
  required: true,
};

export const iconScaleProperty = {
  type: Number,
  required: false,
};

export default {
  components: {
    FontAwesomeIconComponent,
  },
  props: {
    icon: iconProperty,
    scale: iconScaleProperty,
  },
  setup(props) {
    if (props.icon === undefined) {
      throw new Error("DEV: Should not happen."); // TS fix
    }

    const isFontAwesomeIcon = typeof props.icon !== "number";

    if (isFontAwesomeIcon) {
      library.add(props.icon);
    }

    const inlineStyles = computed(() => {
      const styles: StyleValue = {};
      if (props.scale !== undefined) {
        styles["transform"] = "scale(" + props.scale + ")";
      }
      return styles;
    });

    return {
      isFontAwesomeIcon,
      CustomIconComponents,
      inlineStyles,
    };
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: 100%;
  height: 100%;
  svg,
  &:deep(svg) {
    fill: currentColor;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    * {
      transition: fill 0.3s ease;
    }
  }
}
</style>
