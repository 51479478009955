import { RouteLocationNormalized } from "vue-router";

interface state {
  previousPage: RouteLocationNormalized | null;
}

export default {
  state: {
    previousPage: null,
  },
  mutations: {
    savePreviousPage(state: state, page: RouteLocationNormalized) {
      if (page.name) {
        // All our routes are named. We are not interested in any other routes than ours.
        state.previousPage = page;
      } else {
        state.previousPage = null;
      }
    },
  },
  getters: {
    getPreviousPage: (state: state) => {
      return state.previousPage;
    },
  },
};
