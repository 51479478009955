import { RouteLocationNormalized, RouteLocationRaw } from "vue-router";
import { Website } from "@/config";
import { store } from "@/store";
import { Category } from "@/services/repositories/collection/categories";
import { PublicCollectionItem, PublicCollectionItemsFilter, PublicProfileItemsFilter } from "@/services/repositories/publicCollectionItems";
import urlSlug from "url-slug";
import { Filter as EbayFilter } from "@/services/ebay";
import { RouteName, router } from "@/router";
import { CatalogueItem, CatalogueSearchItem } from "@/services/repositories/database";
import { CollectionItem } from "@/services/repositories/collection/items";

export function isPublic(route: RouteLocationNormalized) {
  const isPublicParam = route.meta.public;

  return typeof isPublicParam !== "undefined" && isPublicParam === true;
}

export function setTitle(title?: string): void {
  if (title) {
    document.title = title + " | " + Website.name;
  } else {
    document.title = Website.name;
  }
}

export function storePreviousPage(page: RouteLocationNormalized): void {
  store.commit("savePreviousPage", page);
}

export function createMyCollectionLink(): RouteLocationRaw {
  return {
    name: RouteName.myCollection,
  };
}

export function createCollectionCategoryLink(categoryUrl: string): RouteLocationRaw {
  return {
    name: RouteName.collectionCategory,
    params: {
      url: categoryUrl,
    },
  };
}

export function createCatalogueItemLink(category: Category, item: CatalogueItem | CatalogueSearchItem): RouteLocationRaw {
  const categoryUrl = category.getUrl();
  const itemId = item.getId();
  const nameSeoSlug = urlSlug(item.getName());
  return {
    name: RouteName.catalogueItem,
    params: { categoryUrl, itemId, nameSeoSlug },
  };
}

export function createArticleLink(articleId: number, articleUrl: string): RouteLocationRaw {
  return {
    name: RouteName.article,
    params: {
      id: articleId,
      url: articleUrl,
    },
  };
}

export function createMagazineLink(category?: Category): RouteLocationRaw {
  return {
    name: RouteName.magazine,
    params: {
      category: category ? category.url : undefined,
    },
  };
}

export function createMyItemDetailLink(item: CollectionItem | PublicCollectionItem): RouteLocationRaw {
  return {
    name: RouteName.myItemDetail,
    params: {
      id: item.getId(),
      nameSeoSlug: urlSlug(item.name),
    },
  };
}

export function createPublicItemCardLink(item: PublicCollectionItem, collectorNickname: string): RouteLocationRaw {
  return {
    name: RouteName.publicItemDetail,
    params: {
      nickname: collectorNickname,
      itemId: item.getId(),
      slug: urlSlug(item.name) || "-",
    },
  };
}

export function createCatalogueLink(): RouteLocationRaw {
  return {
    name: RouteName.catalogue,
  };
}

export function createCatalogueCategoryLink(category: Category): RouteLocationRaw {
  return {
    name: RouteName.catalogueCategory,
    params: {
      categoryUrl: category.getUrl(),
    },
  };
}

export function createError404RouteLink(): RouteLocationRaw {
  return {
    name: RouteName.error404,
    params: {
      pathMatch: "404",
    },
  };
}

export function createNotificationsLink(): RouteLocationRaw {
  return {
    name: RouteName.notifications,
  };
}

export function createDashboardRouteLink(): RouteLocationRaw {
  return {
    name: RouteName.dashboard,
  };
}

export function createUserSettingsRouteLink(): RouteLocationRaw {
  return {
    name: RouteName.userSettings,
  };
}

export function createMyProfileRouteLink(filter?: PublicProfileItemsFilter): RouteLocationRaw {
  return {
    name: RouteName.myProfile,
    query: filter?.createUrlParams(),
  };
}

export function createLoginRouteLink(redirectAfterLogin?: string): RouteLocationRaw {
  return {
    name: RouteName.login,
    query: {
      redirect_url: redirectAfterLogin ?? undefined,
    },
  };
}

export function createRegistrationRouteLink(): RouteLocationRaw {
  return { name: RouteName.registration };
}

export const createProfileRouteLink = (nickname: string, filter?: PublicProfileItemsFilter): RouteLocationRaw => {
  return {
    name: RouteName.profile,
    params: {
      nickname: nickname,
    },
    query: filter?.createUrlParams(),
  };
};

export const createPublicHomepageRouteLink = (): RouteLocationRaw => {
  return { name: RouteName.publicHomepage };
};

export const createPasswordRestoreLink = (): RouteLocationRaw => {
  return { name: RouteName.passwordRestore };
};

export const createAddItemLink = (): RouteLocationRaw => {
  return { name: RouteName.addItem };
};

export const createAboutLink = (): RouteLocationRaw => {
  return { name: RouteName.about };
};

export const createGdprLink = (): RouteLocationRaw => {
  return { name: RouteName.gdpr };
};

export const createContactsLink = (): RouteLocationRaw => {
  return { name: RouteName.contacts };
};

export const createLegalInformationsLink = (): RouteLocationRaw => {
  return { name: RouteName.legalInformation };
};

export const createProfileInitializationRouteLink = (): RouteLocationRaw => {
  return { name: RouteName.profileInitialization };
};

export const createBooksRouteLink = (): RouteLocationRaw => {
  return { name: RouteName.books };
};

export const createChatLink = (buddyId?: number, buddyNickname?: string): RouteLocationRaw => {
  const route: RouteLocationRaw = { name: RouteName.chat };
  if (buddyId && buddyNickname) {
    route.params = { buddyId, buddyNickname };
  }
  return route;
};

export const createEbayListingRouteLink = (filter?: EbayFilter): RouteLocationRaw => {
  if (filter) {
    return {
      name: RouteName.ebayListing,
      query: {
        q: filter.q,
        sort: filter.sort,
        category: filter.category,
      },
    };
  } else {
    return { name: RouteName.ebayListing };
  }
};

export const createPublicItemsListRouteLink = (filter?: PublicCollectionItemsFilter): RouteLocationRaw => {
  if (filter) {
    return {
      name: RouteName.publicItemsList,
      query: filter.createUrlParams(),
    };
  } else {
    return { name: RouteName.publicItemsList };
  }
};

export function getUrlParamAsString(paramName: string): string {
  const route = router.currentRoute.value;
  const value = route.query[paramName];

  if (value === undefined) {
    throw new Error("Parameter '" + paramName + "' not found in URL.");
  }

  if (typeof value !== "string") {
    throw new Error("Parameter '" + paramName + "' is not a string.");
  }

  return value;
}

export function getUrlParamAsNumber(paramName: string): number {
  const route = router.currentRoute.value;
  const value = route.query[paramName];

  if (value === undefined) {
    throw new Error("Parameter '" + paramName + "' not found in URL.");
  }

  if (typeof value !== "string") {
    throw new Error("DEV: Parameter '" + paramName + "' is not a string.");
  }

  if (isNaN(+value)) {
    throw new Error("Parameter '" + paramName + "' is not a number.");
  }

  return +value;
}

export function getUrlParamAsNumberArray(paramName: string): number[] {
  const route = router.currentRoute.value;
  const value = route.query[paramName];

  if (value === undefined) {
    throw new Error("Parameter '" + paramName + "' not found in URL.");
  }

  if (!Array.isArray(value)) {
    throw new Error("DEV: Parameter '" + paramName + "' is not an array.");
  }

  return value.map(Number);
}

export function getUrlParamAsBool(paramName: string, allowZeroAndOne = false): boolean {
  const route = router.currentRoute.value;
  const value = route.query[paramName];

  if (value === undefined) {
    throw new Error("Parameter '" + paramName + "' not found in URL.");
  }

  if (typeof value !== "string") {
    throw new Error("DEV: Parameter '" + paramName + "' is not a string.");
  }

  if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  }

  if (allowZeroAndOne) {
    if (value === "1") {
      return true;
    } else if (value === "0") {
      return false;
    }
  }

  throw new Error("URL parameter '" + paramName + "' has invalid value.");
}
