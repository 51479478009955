export enum Layout {
  error,
  loginAndRegistration,
  app,
  appDialog,
  public,
}

export enum ContentWidth {
  middle,
  narrow,
  wide,
  full,
}

export interface LayoutSettings {
  name: Layout;
  contentWidth?: ContentWidth;

  // dialog layout settings:
  width?: string;
  height?: string;
  class?: string;
}
