import { API } from "@/services/api2";
import { getCookie, setCookie } from "@/services/cookies";
import { IndexedDb } from "@/services/indexedDb";
import { logout as facebookLogout } from "./FacebookLoginService";
import { logout as googleLogout } from "./GoogleLoginService";
import { User } from "./User";
import { ApiResponse_ThirdPartyLoginAuthentication, LoginProvider, RawUserEssentialsData } from "./internalUserTypes";
import { AxiosError } from "axios";
import { router } from "@/router";
import { createLoginRouteLink, createProfileInitializationRouteLink } from "@/utilities/routerHelpers";
import { getUserData } from "./apiEndpointsAccount";
import { AuthenticationException, TokenNotValidException, UserNotEstablishedException } from "./userExceptions";
import { store } from "@/store";
import { LoginEvent, trackEvent } from "@/services/googleAnalytics";
import { addTokenToUrl, catch401 } from "../api2/apiHelpers";

type Credentials = { email: string; password: string } | { token: string };

type AuthenticationPromise = Promise<User> | null;

const AuthStatusOk = 1;
const AuthStatusTokenNotFound = 3;
type ApiResponse_Authentication_error = {
  status: 2 | typeof AuthStatusTokenNotFound | 4 | 5 | 6 | 7 | 8; // We don't care about other statuses then "token not found".
  message: string;
};
type ApiResponse_Authentication =
  | {
      status: typeof AuthStatusOk;
      token: string;
      user: RawUserEssentialsData;
    }
  | ApiResponse_Authentication_error;

const BASE_URL = "user/";
const PROFILE_BASE_URL = "profile/";

/** Authentication API call cache. */
let authentication: AuthenticationPromise = null;

/** If cookie with this name is present, it means someone was already loggedin in this exact browser. */
const PreviousLoginCookieName = "dsr_swli";

/** IndexedDb token key. */
const DatabaseTokenKey = "userToken";

/**
 * Looks for browser saved user-token. If found, tries to verify it through API.
 */
export async function checkUserOnInitialPageLoad(): Promise<AuthenticationPromise> {
  if (authentication === null) {
    const token = await IndexedDb.get(DatabaseTokenKey);
    if (token) {
      return authenticate({ token });
    } else {
      return Promise.resolve(null);
    }
  } else {
    return authentication;
  }
}

/**
 * Requests User data from API in exchange for (email,password)|(token).
 */
async function authenticate(credentials: Credentials): Promise<AuthenticationPromise> {
  if (authentication === null) {
    authentication = API.postPublic<ApiResponse_Authentication>(BASE_URL + "authenticate", {}, credentials)
      .then((response) => {
        const status = response.data.status;
        if (status === AuthStatusOk) {
          const token = response.data.token;
          const userData = response.data.user;
          return establishUserForThisSession(token, userData);
        }

        return Promise.reject(response.data); // awful... we need to rework BE for login.
      })
      .catch((error: UserNotEstablishedException | AxiosError | ApiResponse_Authentication_error) => {
        deleteUserToken();

        if (error instanceof UserNotEstablishedException) {
          const user = store.getters.getUser;
          if (!user) {
            throw new Error("DEV: Should not happen. User should be logged-in even when UserNotEstablishedException occurs.");
          }

          const redirect = createProfileInitializationRouteLink();
          router.push(redirect);
          return user;
        } else if (error instanceof AxiosError) {
          throw error;
        } else {
          // Error sent in response.data ... awful... see above
          if (error.status === AuthStatusTokenNotFound) {
            throw new TokenNotValidException(); // We don't want any message here.
          } else {
            throw new AuthenticationException(error.message);
          }
        }
      });
  }

  return authentication;
}

/**
 * Login via given email and password => login form.
 */
export async function login(email: string, password: string): Promise<AuthenticationPromise> {
  authentication = null;
  return authenticate({ email, password });
}

/**
 * Establishes user for current browser session.
 * Creates User entity, stores user token in browser,
 */
export async function establishUserForThisSession(token: string, userData: RawUserEssentialsData): Promise<User> {
  storeUserToken(token);
  markLoginInBrowser();
  const user = createUser(userData, token);
  trackEvent(new LoginEvent(user.id));
  store.commit("login", user);
  return loadUserSettings(user);
}

export async function thirdPartyLogin(token: string, registerNewsletter: boolean, provider: LoginProvider): Promise<ApiResponse_ThirdPartyLoginAuthentication> {
  const url = BASE_URL + "third-party-login";
  const data = {
    token,
    provider,
    newsletter: registerNewsletter ? 1 : 0,
  };

  return API.postPublic<ApiResponse_ThirdPartyLoginAuthentication>(url, {}, data).then((response) => response.data);
}

/**
 * Returns currently logged in user or undefined if there is none.
 * Waits for currently running authentication (if there is any).
 */
export async function getUser(): Promise<User | undefined> {
  await authentication;
  return store.getters.getUser;
}

/**
 * Returns currently logged in user or throws error.
 * Do not wait for possible authentication in progress.
 */
export function requireUser(): User {
  const user = store.getters.getUser;
  if (!user) {
    // @todo - should be some more specific exception
    throw new Error("User requested. However no user is logged in.");
  }

  return user;
}

/**
 * Logs out user. Redirects to login page.
 */
export async function logout(invalidateInApi = true): Promise<void> {
  const user = store.getters.getUser;
  if (user) {
    if (user.isFacebookAccount()) {
      facebookLogout();
    } else if (user.isGoogleAccount()) {
      googleLogout();
    }

    if (invalidateInApi) {
      const url = addTokenToUrl(PROFILE_BASE_URL + "logout");
      await API.put(url).catch(catch401);
    }

    deleteUserToken();
    store.commit("logout");
    const redirectUrl = router.currentRoute.value.fullPath;
    router.push(createLoginRouteLink(redirectUrl));
  }

  authentication = null;
}

/**
 * Helper function which creates User entity.
 */
function createUser(data: RawUserEssentialsData, token: string): User {
  const ShowPricesValue = 1;
  const shouldBePricesVisible = data.showPrices === ShowPricesValue;

  const user = new User(data.id, data.email, data.type, data.name);
  user.setImage(data.image);
  user.setShowPrices(shouldBePricesVisible);
  user.setToken(token);

  return user;
}

async function loadUserSettings(user: User): Promise<User> {
  return getUserData().then((userData) => {
    user.data = userData;
    return user;
  });
}

/**
 * Helper function for setting "someone was logged in" flag.
 */
function markLoginInBrowser(): void {
  const expiration = new Date();
  expiration.setFullYear(expiration.getFullYear() + 1);
  setCookie(PreviousLoginCookieName, "1", expiration.toUTCString(), "/");
}

export function wasSomeoneEverLoggedIn(): boolean {
  const cookie = getCookie(PreviousLoginCookieName);
  return cookie !== null;
}

export function resetAuthentication(): void {
  authentication = null;
}

/**
 * Helper - stores user token in browser.
 */
function storeUserToken(token: string): void {
  IndexedDb.set(DatabaseTokenKey, token);
}

/**
 * Helper - removes browser-stored user token.
 */
function deleteUserToken(): void {
  IndexedDb.delete(DatabaseTokenKey);
}
