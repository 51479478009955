<template>
  <div class="confirmation-dialog" v-if="isVisible" @click.stop="close">
    <div class="confirmation-dialog__body" @click.stop>
      <div class="confirmation-dialog__header" v-if="settings.header">
        <div v-html="settings.header" />
        <button class="confirmation-dialog__close" @click.stop="close">
          <icon-font-awesome name="times" weight="solid" />
        </button>
      </div>
      <div class="confirmation-dialog__message" v-if="settings.message" v-html="settings.message" />
      <div class="confirmation-dialog__buttons">
        <button class="btn btn--secondary" @click="close">
          <template v-if="settings.cancelButton">{{ settings.cancelButton }}</template>
          <template v-else>{{ $t("no") }}</template>
        </button>
        <button class="btn" @click="accept">
          <template v-if="settings.okButton">{{ settings.okButton }}</template>
          <template v-else>{{ $t("yes") }}</template>
        </button>
      </div>
      <div class="confirmation-dialog__remember" v-if="settings.showRemember">
        <input-checkbox v-model="doNotShowNextTimeValue" :label="translate('doNotShowNextTime')" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Events } from "@/types";
import { onMounted, ref } from "vue";
import { allowBodyScroll, disableBodyScroll } from "@/services/bodyScrollDisable";
import IconFontAwesome from "./IconFontAwesome.vue";
import InputCheckbox from "./InputCheckbox.vue";
import { translate } from "@/services/translation";
import { ConfirmationDialogSettings } from "@/services/popups";

onMounted(() => window.eventBus.on(Events.confirm, show));

const settings = ref<ConfirmationDialogSettings>({});
const isVisible = ref<boolean>(false);
const doNotShowNextTimeValue = ref<boolean>(false);

const show = (confirmationSettings: ConfirmationDialogSettings) => {
  disableBodyScroll();
  settings.value = confirmationSettings;
  isVisible.value = true;
  doNotShowNextTimeValue.value = false;
};

const close = () => {
  allowBodyScroll();
  isVisible.value = false;
};

const accept = () => {
  if (settings.value?.accept) {
    if (settings.value.showRemember) {
      const remember = doNotShowNextTimeValue.value;
      settings.value.accept(remember);
    } else {
      settings.value.accept();
    }
  }

  close();
};
</script>

<style lang="scss" scoped>
.confirmation-dialog {
  --padding: 20px;
  --sectionsGap: 30px;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#000, 0.65);
  z-index: calc(var(--overlay-z-index) + 2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  &__header {
    --correction: calc(var(--padding) * -1);
    background: linear-gradient(to right, var(--primary-color), var(--dark-green));
    padding: var(--padding);
    margin: var(--correction) var(--correction) 0 var(--correction);
    border-top-right-radius: var(--small-radius);
    border-top-left-radius: var(--small-radius);
    color: #fff;
    font-weight: 600;
    font-size: 1.25rem;

    display: flex;
    justify-content: space-between;
  }
  &__close {
    background: transparent;
    border: 0;
    color: #fff;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.1);
    }
  }

  &__body {
    border-radius: var(--small-radius);
    background: #fff;
    width: 100%;
    padding: var(--padding);
    max-width: 560px;
  }

  &__message {
    margin: var(--sectionsGap) 0;
  }

  &__buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    margin: var(--sectionsGap) 0;
  }
  &__remember {
    margin-top: var(--sectionsGap) 0;
  }
}
</style>
