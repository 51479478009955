export default {
  publicHomepage: {
    hero: {
      textLine1: "Pro všechny",
      textLine2: "vášnivé sběratele",
      categories: {
        lego: "LEGA",
        watch: "hodinek",
        vinyls: "vinylů",
        art: "umění",
        alcohol: "alkoholu",
        cards: "kartiček",
      },
      cta: "Vytvoř si sbírku",
    },
    why: {
      heading: "Co ti přinese Colectio",
      li1: "evidenci vlastních sběratelských předmětů",
      li2: "sdílení svého portfolia a jeho prodej",
      li3: "450 000 předmětů v databázích",
      li4: "přehled o aktuální ceně své sbírky",
      li5: "a to vše je ZDARMA",
      cta: "Vytvoř si sbírku",
    },
    grow: {
      heading: "Neustále rosteme",
      label: "…aktuální počet předmětů ve vašich sbírkách",
    },
    how: {
      heading: "Jak to celé funguje?",
      collection: {
        subheading: "Sbírka",
        heading: "Měj přehled o své sbírce",
        text: "Zaznamenávej si svoji sbírku online, vkládej sběratelské předměty nejen z databází a sleduj vývoj její hodnoty. Vše na jednom místě.",
        cta: "Více informací",
      },
      profile: {
        subheading: "Profil",
        heading: "Zveřejňuj předměty ve svém profilu",
        text: "Chceš se pochlubit svými sběratelskými skvosty nebo je dokonce prodávat? Vytvoř si veřejný profil a ukaž světu jen ty kousky, které chceš.",
        cta: "Více informací",
      },
      marketplace: {
        subheading: "Prodej",
        heading: "Prodávej i mimo platformu",
        text: "Pomocí jednoduchého zveřejnění předmětů a doplnění parametrů a ceny si vytvoříš svůj malý online obchod. Zkopíruj svůj unikátní odkaz a nabízej své předměty ostatním sběratelům.",
        cta: "Více informací",
      },
      pricelist: {
        subheading: "Ceník",
        heading: "Naceňuj si předměty",
        text: "Neznáš cenu svých předmětů, nebo si nejsi jistý? Vytvořili jsme pro tebe naceňovač. Jednoduše si zobraz poslední prodeje či nabízené ceny a zjisti, jakou mají ve světě cenu.",
        cta: "Více informací",
      },
    },
    magazine: {
      subheading: "Magazín",
      heading: "Objevuj svět sběratelství",
      moreBtn: "Více článků",
    },
    reviews: {
      heading: "Zkušenosti uživatelů",
      review1: {
        text: "Do nedávna jsem používal k záznamu své sbírky excelovovou tabulku. Colectio mi pomohlo přenést evidenci sbírky do mnohem příjemnějšího prostředí, s intuitivnějšími funkcemi a jednodušším vkládáním. Těším se na nové funkce, které se chystají.",
        name: "Jaroslav Prudký",
        label: "Sběratel modelů",
      },
      review2: {
        text: "Vždycky jsem při obchodování na sociálních sítích složitě vepisoval ceny k předmětům, které chci nabízet. Nyní jednoduše pošlu odkaz s nabízenými předměty. Ušetřím spoustu času a co hlavně, ozývá se mi víc zájemců.",
        name: "Matěj Staněk",
        label: "Sneakers reseller",
      },
      review3: {
        text: "Oproti většině jiných sběratelů jsem dost všestranný. Sbírám od všeho něco. Colectio mi nabídlo možnost mít všechny možné sbírky přehledně a uceleně na jednom místě a ještě je nabízet dál, skoro jako bych měl malý e-shop.",
        name: "Kryštof Gellner",
        label: "Všestranný sběratel",
      },
    },
  },
};
