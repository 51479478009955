import { API } from "@/services/api/API";
import { createLegoSet } from "./legoSetFactory";
import { createLegoTheme } from "./legoThemeFactory";

const BASE_URL = "lego/";

const Sets = {
  url: BASE_URL,

  getById: async function (id) {
    return API.get(this.url + "get-set-by-id/" + id).then((response) => {
      if (response.status == "ok") {
        response.set = createLegoSet(response.set);
      }

      return response;
    });
  },

  getEssentials: async function (id) {
    return API.get(this.url + "get-set-essentials/" + id, false).then((data) => {
      if (data.status === "error") {
        throw new Error("LEGO® set #" + id + " not found.");
      }

      return createLegoSet(data);
    });
  },

  search: async function (q) {
    return API.get(this.url + "search-set/" + q).then((response) => {
      return response.map((set) => {
        const hasImage = set.image && set.image.length !== 0;
        set.images = hasImage ? [set.image] : [];

        return createLegoSet(set);
      });
    });
  },

  get: async function (filter) {
    return API.post(BASE_URL + "get", filter).then((response) => {
      const result = [];

      response.sets.forEach((set) => {
        result.push(createLegoSet(set));
      });

      return {
        items: result,
        totalCount: response.totalCount,
      };
    });
  },

  save: async function (data) {
    return API.post(this.url + "save", data);
  },
};

const Themes = {
  url: BASE_URL,

  getTree: async function () {
    return API.get(this.url + "get-themes-tree").then((rawTreeData) => {
      const tree = [];

      rawTreeData.forEach((node) => {
        const subthemes = node.subthemes.map((subnode) => subnode.theme);
        const theme = createLegoTheme(node.theme, subthemes);
        tree.push(theme);
      });

      return tree;
    });
  },
};

export { Sets, Themes };
