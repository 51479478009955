import axios from "axios";
import { handleError } from "./errorHandlers";
import { getUser } from "@/services/user";

export const API = {
  get,
  getWithParams,
  post,
  put,
};

async function get(url, addToken = true) {
  const fullUrl = await createFullUrl(url, addToken);

  const response = await axios.get(fullUrl).catch((e) => {
    handleError(e);
  });

  return response.data;
}

async function getWithParams(url, params, addToken = true) {
  const fullUrl = await createFullUrl(url, addToken);

  const response = await axios.get(fullUrl, { params: params }).catch((e) => {
    handleError(e);
  });

  return response.data;
}

async function post(url, data, headers = {}, addToken = true, progressListener) {
  const options = {};
  options.method = "post";
  options.url = await createFullUrl(url, addToken);
  options.data = data;
  options.headers = headers;

  if (progressListener) {
    options.onUploadProgress = progressListener;
  }

  return axios(options)
    .then((response) => response.data)
    .catch((e) => handleError(e));
}

async function put(url, data = {}, headers = {}, addToken = true) {
  const options = {};
  options.method = "put";
  options.url = await createFullUrl(url, addToken);
  options.data = data;
  options.headers = headers;

  return axios(options)
    .then((response) => response.data)
    .catch((e) => handleError(e));
}

async function createFullUrl(url, addToken) {
  let fullUrl = import.meta.env.VITE_API_URL;

  if (addToken) {
    fullUrl += (await getUser())?.getToken() + "/";
  }

  fullUrl += url;

  return fullUrl;
}
