<template>
  <NotificationBar v-if="!isLatest" color="green">
    <div class="version-bar">
      <div class="version-bar__icon desktop-only">
        <Icon :icon="faSparkles" />
      </div>
      <p class="version-bar__text" v-html="translate('versionBar.text')" />
      <button class="btn btn--secondary" @click="router.go(0)">
        <span class="desktop-only">{{ translate("versionBar.btnDesktop") }}</span>
        <span class="mobile-only">{{ translate("versionBar.btnMobile") }}</span>
      </button>
    </div>
  </NotificationBar>

  <transition name="fade-500">
    <div v-if="isInitialLoginCheckFinished && layout" :class="{ 'page-wrapper': true, 'with-notification': isSomeNotificationVisible }">
      <error-layout v-if="layout.name === Layout.error" />
      <login-and-registration-layout v-else-if="layout.name === Layout.loginAndRegistration" />
      <app-layout v-else-if="layout.name === Layout.app" :settings="layout" />
      <dialog-layout v-else-if="layout.name === Layout.appDialog" :settings="layout" />
      <public-layout v-else-if="layout.name === Layout.public" :settings="layout" />
    </div>

    <div v-else class="loader">
      <img src="/images/logo-c-color.svg" />
    </div>
  </transition>

  <gallery-popup />
  <confirmation-dialog />
  <overlay-message />
</template>

<script setup lang="ts">
import OverlayMessage from "@/components/OverlayMessage.vue";
import GalleryPopup from "@/components/GalleryPopup.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import { TokenNotValidException, UserNotEstablishedException, checkUserOnInitialPageLoad, getUser, resetAuthentication } from "@/services/user";
import { computed, defineAsyncComponent, onMounted, onUnmounted, ref } from "vue";
import { isArrowLeft, isArrowRight, isEscape } from "./services/keyDownHelpers";
import { ContentWidth, Events, LayoutSettings, Layout } from "./types";
import { useStore } from "vuex";
import { watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useRedirectToNicknameDialog, useTranslation } from "./composables";
import { createLoginRouteLink } from "./utilities/routerHelpers";
import { useVersion } from "./composables/version";
import Icon from "@/components/basics/Icon.vue";
import { faSparkles } from "@fortawesome/pro-solid-svg-icons";
import NotificationBar from "@/components/NotificationBar.vue";

const { translate } = useTranslation();

const layout = ref<LayoutSettings>();

const { version, isLatest, checkIsLatest } = useVersion();
console.log("%c" + version, "padding: 4px 8px; color: #fff; background: #32C380; border-radius: 5px");

onMounted(() => {
  window.addEventListener("keyup", onKeyUp);
  window.addEventListener("resize", onWindowResize);
  window.eventBus.on(Events.updateContentWidth, updateContentWidth);
  onWindowResize();
});

onUnmounted(() => {
  window.removeEventListener("keyup", onKeyUp);
  window.removeEventListener("resize", onWindowResize);
  window.eventBus.off(Events.updateContentWidth, updateContentWidth);
});

const ErrorLayout = defineAsyncComponent(() => import("@/components/layouts/ErrorLayout.vue"));
const LoginAndRegistrationLayout = defineAsyncComponent(() => import("@/components/layouts/LoginAndRegistrationLayout.vue"));
const AppLayout = defineAsyncComponent(() => import("@/components/layouts/app/Layout.vue"));
const DialogLayout = defineAsyncComponent(() => import("@/components/layouts/DialogLayout.vue"));
const PublicLayout = defineAsyncComponent(() => import("@/components/layouts/public/Layout.vue"));

const onKeyUp = (e: KeyboardEvent) => {
  if (isEscape(e)) {
    window.eventBus.emit(Events.keyEscape);
  } else if (isArrowRight(e)) {
    window.eventBus.emit(Events.keyArrowRight);
  } else if (isArrowLeft(e)) {
    window.eventBus.emit(Events.keyArrowLeft);
  }
};

const store = useStore();
const onWindowResize = () => store.commit("saveIsPhone", document.documentElement.clientWidth < 1050);

const route = useRoute();
const router = useRouter();

watch(route, async (to) => {
  const defaultLayout = to.meta.layout ?? { name: Layout.public };
  const loggedInLayout = to.meta.layoutLoggedIn;

  if (loggedInLayout) {
    try {
      const user = await getUser();
      layout.value = user ? loggedInLayout : defaultLayout;
    } catch (e: unknown) {
      if (e instanceof TokenNotValidException) {
        // It's okay, don't throw error, we can fallback to layout for not logged in users.
        layout.value = defaultLayout;
      } else {
        throw e;
      }
    }
  } else {
    layout.value = defaultLayout;
  }
});

/** This needs huge refactoring and should be in router... */
const onLogin = () => {
  const routeName = route.name;

  if (typeof routeName === "string" && !routeName.startsWith("error-")) {
    const redirect = route.query.redirect_url;
    if (typeof redirect === "string") {
      router.push(redirect);
    }
  }
};

const isInitialLoginCheckFinished = ref<boolean>(false);
const { redirectToNicknameDialog } = useRedirectToNicknameDialog();
checkUserOnInitialPageLoad()
  .then((user) => {
    if (user) {
      onLogin();
    }
  })
  .catch((e: unknown) => {
    if (e instanceof UserNotEstablishedException) {
      redirectToNicknameDialog();
    } else if (e instanceof TokenNotValidException) {
      resetAuthentication();
      const isRoutePublic = route.name === undefined || route.meta.public === true; // route.name === undefined ... first page load
      if (!isRoutePublic) {
        router.push(createLoginRouteLink());
      }
    } else {
      throw e;
    }
  })
  .finally(() => {
    isInitialLoginCheckFinished.value = true;
  });

const updateContentWidth = (newWidth: ContentWidth) => {
  if (layout.value) {
    layout.value.contentWidth = newWidth;
  }
};

// Latest bundle version check
const checkInterval = 900000; // 900000 === 15 minutes
onMounted(() => setInterval(() => checkIsLatest(), checkInterval));

const isSomeNotificationVisible = computed(() => !isLatest.value);
</script>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include for-size(md) {
    min-height: 100dvh;
  }

  &.with-notification {
    padding-top: var(--notificationBarHeight);
  }
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    width: 100px;
    max-width: 90%;
    animation: loading 3s ease-in-out infinite;
    opacity: 0.9;
  }
}
.version-bar {
  display: flex;
  align-items: center;
  gap: var(--spacing2x);
  &__icon {
    width: 20px;
    height: 20px;
    color: var(--greenPrimary);
  }
  &__text {
    margin: 0 var(--spacing2x) 0 0;
  }
}

@keyframes loading {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(360deg);
  }
}
</style>
