import { AccountType } from "./user/internalUserTypes";
import * as GoogleAnalytics from "@/services/googleAnalytics";
import * as FacebookPixel from "@/services/facebookPixel";

export function trackNewRegistrationEvent(userId: number, accountType: AccountType): void {
  const gaEvent = new GoogleAnalytics.AccountVerifiedEvent(userId, accountType);
  GoogleAnalytics.trackEvent(gaEvent);

  // In FB Pixel we don't care what account type we've created.
  const fbEvent = FacebookPixel.FacebookPixelEvent.CompleteRegistration;
  FacebookPixel.trackEvent(fbEvent);
}
