<template>
  <label class="checkbox" @click.stop>
    <div :class="{ checkbox__control: true, checked: isChecked }">
      <input type="checkbox" v-model="isChecked" @change="onChange" />
      <icon-font-awesome weight="solid" name="check" />
    </div>

    <span class="checkbox__label" v-if="label">
      {{ label }}
    </span>
  </label>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import IconFontAwesome from "./IconFontAwesome.vue";

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  modelValue: Boolean,
});

// Value
const isChecked = ref(true);

onMounted(() => setValueFromProps());
watch(
  () => props.modelValue,
  () => setValueFromProps()
);

const setValueFromProps = () => {
  isChecked.value = props.modelValue;
};

// Emit change
const emit = defineEmits<{
  (e: "update:modelValue", payload: boolean): void;
  (e: "change", payload: boolean): void;
}>();

const onChange = () => {
  emit("update:modelValue", isChecked.value);
  emit("change", isChecked.value);
};
</script>

<style lang="scss" scoped>
.checkbox {
  --size: 20px;
  --radius: 50%;
  --gap: 10px;
  --padding: 2px;

  display: flex;
  align-items: center;
  cursor: pointer;

  &__control {
    position: relative;
    width: var(--size);
    height: var(--size);
    margin-right: var(--gap);
    border: 1px solid #000;
    border-radius: var(--radius);
    flex-shrink: 0;

    &.checked {
      background: var(--primary-color);
      border-color: var(--primary-color);
      .icon {
        opacity: 1;
      }
    }

    &:hover {
      background: #000;
      border-color: #000;
      .icon {
        opacity: 1;
      }
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .icon {
    position: absolute;
    left: var(--padding);
    top: var(--padding);
    width: calc(var(--size) - 3 * var(--padding));
    height: calc(var(--size) - 3 * var(--padding));
    color: #fff;
    opacity: 0;
  }

  &__label {
    font-size: 0.9rem;
    padding-bottom: 3px;
  }
}
</style>
