import { RawLegoThemeData } from "@/types";
import { LegoTheme } from "./LegoTheme";

export function createLegoTheme(data: RawLegoThemeData, subthemesData?: RawLegoThemeData[]): LegoTheme {
  const theme = new LegoTheme(data.id, data.name);

  if (subthemesData) {
    const subthemes: LegoTheme[] = [];
    subthemesData.forEach((rawSubthemeData) => {
      subthemes.push(createLegoTheme(rawSubthemeData));
    });
    theme.setSubthemes(subthemes);
  }

  return theme;
}
