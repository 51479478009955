import { Entity } from "../Entity";

export class LegoTheme extends Entity {
  private subthemes: LegoTheme[];

  constructor(protected id: number, protected name: string) {
    super(id);
  }

  public getName(): string {
    return this.name;
  }

  public setSubthemes(themes: LegoTheme[]): void {
    this.subthemes = themes;
  }

  public getSubthemes(): LegoTheme[] {
    if (this.subthemes === undefined) {
      throw new Error("DEV: Cannot getSubthemes() because subthemes were not loaded.");
    }

    return this.subthemes;
  }

  public hasSubthemes(): boolean {
    return this.getSubthemes().length > 0;
  }
}
