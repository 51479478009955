import { Image } from "../images";
import { Entity } from "../Entity";
import { CataloguePrice, CatalogueItemParameter } from ".";
import { IsInfoPriceFlag, IsWidgetIdentificator } from "@/types";

export class CatalogueItem extends Entity {
  protected name = "";
  protected images: Image[] = [];
  protected prices: CataloguePrice[] = [];
  protected params: CatalogueItemParameter[] = [];

  public setName(name: string): void {
    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public getCoverImage() {
    if (this.images.length > 0) {
      return this.images[0];
    } else {
      return null;
    }
  }

  public addPrice(price: CataloguePrice): void {
    this.prices.push(price);
  }

  public getPrices(): CataloguePrice[] {
    return this.prices;
  }

  public addImage(img: Image): void {
    this.images.push(img);
  }

  public getImages(): Image[] {
    return this.images;
  }

  public getCurrentPrice(): number | null {
    if (this.prices.length > 0) {
      return this.prices[0].price;
    } else {
      return null;
    }
  }

  public getIdentification(): string | undefined {
    // overwritten in children classes
    return this.getParamByFlag(IsWidgetIdentificator)?.getValue();
  }

  public addParam(param: CatalogueItemParameter): void {
    this.params.push(param);
  }

  public getParams(): CatalogueItemParameter[] {
    return this.params;
  }

  public getParamByFlag(flagName: string): CatalogueItemParameter | undefined {
    return this.getParams().find((param) => param.hasFlag(flagName));
  }

  public getParamById(paramId: number): CatalogueItemParameter | undefined {
    return this.getParams().find((param) => param.getId() === paramId);
  }

  public getInfoPrice(): CatalogueItemParameter | undefined {
    return this.getParamByFlag(IsInfoPriceFlag);
  }

  /**
   * CatalogueItemCard and ItemCard shares ItemParamCurrentPrice.vue component which uses this method.
   * ItemParamCurrentPrice.vue should be refactored to distinguish between catalogue item and collection item...
   */
  public isPriceAdjusted(): boolean {
    return false;
  }
}
