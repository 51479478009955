import { ParameterValue } from "./ParameterValue";
import { RawParameterValueData } from "@/types";

export function createParameterValue(rawValueData: RawParameterValueData): ParameterValue {
  const value = new ParameterValue(rawValueData.id, rawValueData.value);
  value.setColumn(rawValueData.column);
  value.setIsCustom(rawValueData.isCustom === 1);
  value.setIsDefault(rawValueData.isDefault === 1);
  return value;
}
