export default {
  publicItemsList: {
    title: "Předměty sběratelů",
    heading: "Předměty sběratelů",
    filter: {
      heading: "Filtr",
      fulltextPlaceholder: "Název / kód předmětu",
      categoryPlaceholder: "Všechny kategorie",
      price: {
        label: "Cena",
        from: "od",
        to: "do",
        includeNegotiablePrice: "cena dohodou",
      },
      reset: "obnovit vše",
      onlyForSale: "pouze k prodeji",
      toggleBtn: "Filtr",
      showBtn: "Nic nenalezeno | Zobrazit {count} předmět | Zobrazit {count} předměty | Zobrazit {count} předmětů",
      showBtnLoading: "Vyhledávám předměty…",
    },
    grid: {
      emptyMsg: "Bohužel, tvému filtru neodpovídá žádná položka. Zkus vyhledávání upravit, třeba se tu tvůj vybraný kousek někde schovává.",
      errorMsg: "Ops. Chyba vyhledávání.",
      errorMsgBtn: "Obnovit stránku",
    },
  },
};
