import Vuex from "vuex";
import breadcrumbsStore from "./breadcrumbsStore";
import collectionItemsList from "./collectionItemsList";
import displaySizeStore from "./displaySizeStore";
import catalogueFilterStore from "./catalogueFilterStore";
import routerHistoryStore from "./routerHistoryStore";
import userStore from "./userStore";

export const store = new Vuex.Store({
  modules: {
    breadcrumbsStore,
    displaySizeStore,
    catalogueFilterStore,
    collectionItemsList,
    routerHistoryStore,
    userStore,
  },
});
