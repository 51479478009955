import { Category } from "@/services/repositories/collection/Collection";
import { ItemsFilter, ItemsListType } from "@/services/repositories/collection/items";

interface state {
  filter: ItemsFilter | undefined;
}

export default {
  state: {
    filter: undefined,
  },
  mutations: {
    saveLastFilter(state: state, filter: ItemsFilter) {
      state.filter = filter;
    },
    clearStoredFilter(state: state) {
      state.filter = undefined;
    },
  },
  getters: {
    getLastCollectionItemsFilter: (state: state) => (category: Category | undefined, listType: ItemsListType) => {
      const requestedCategoryId = category?.getId();
      const filter = state.filter;

      if (filter && filter.categoryId === requestedCategoryId && filter.listType === listType) {
        return filter;
      } else {
        return null;
      }
    },
    getLastCollectionItemsFilterOfAnyType: (state: state) => {
      return state.filter;
    },
  },
};
