import { CategoryId, CatalogueFilterDropdownOption, DatabaseFilterParameterValue, LegoFilterDropdownOption, VinylsFilterYearFrom, VinylsFilterYearTo, WatchFilterApiData, CatalogueFilterFieldType as FieldType } from "@/types";
import { generateRangeArray } from "@/utilities/arrayHelpers";
import { Category } from "../collection/Collection";
import { CatalogueItemsCountPerPage } from "@/config";
import { LegoTheme, Themes } from "../lego";
import { translate } from "@/services/translation";

/**
 * Wraps current catalogue filter data.
 */
export class CatalogueFilter {
  public page = 0;
  public perPage: number = CatalogueItemsCountPerPage;

  private allowedFilterParamsKeys: string[] = [];

  // number key type only for old "watch" category
  private categorySpecificParams: Map<string | number, DatabaseFilterParameterValue> = new Map();

  constructor(public category: Category) {
    // Load allowed filter params names.
    getFilterFields(category).forEach((filterField) => {
      this.allowedFilterParamsKeys.push(filterField.getName());
    });
  }

  public createApiParams(): { [key: string]: DatabaseFilterParameterValue } {
    return {
      categoryId: this.category.getId(),
      page: this.page,
      perPage: this.perPage,
      ...Object.fromEntries(this.categorySpecificParams),
    };
  }

  /**
   * Old category "watch" has different filter specification.
   */
  public createWatchApiParams(): WatchFilterApiData {
    const params = <WatchFilterApiData>{
      page: this.page,
      perPage: this.perPage,
      filter: [],
    };

    for (const [name, value] of this.categorySpecificParams.entries()) {
      if (name === "fulltext") {
        if (value !== "") {
          params.filter.push({ parameter: "fulltext", q: value });
        }
      } else {
        const numberKey = Number(value);
        if (!isNaN(numberKey) && value !== null) {
          params.filter.push({ parameterId: name, optionId: value });
        }
      }
    }

    return params;
  }

  /**
   * ParamName can be number only because of watch category - remove after watch category conversion into new API.
   */
  public setParam(paramName: string | number, paramValue: DatabaseFilterParameterValue): void {
    if (this.category.is(CategoryId.watch)) {
      // old category - remove in future
      this.categorySpecificParams.set(paramName, paramValue);
      return;
    }

    if (typeof paramName !== "string") {
      throw new Error("DEV: Number parameter name in filter is allowed only for old API categories.");
    }

    if (!this.allowedFilterParamsKeys.includes(paramName)) {
      throw new Error("DEV: Parameter '" + paramName + "' is not allowed in filter for category #" + this.category.getId() + ".");
    }

    const isValueEmpty = paramValue === "" || paramValue === null;
    const paramAlreadyExists = this.categorySpecificParams.has(paramName);
    if (isValueEmpty && paramAlreadyExists) {
      this.categorySpecificParams.delete(paramName);
    } else {
      this.categorySpecificParams.set(paramName, paramValue);
    }
  }

  /**
   * ParamName can be number only because of watch category - remove after watch category conversion into new API.
   */
  public getParam(paramName: string | number): DatabaseFilterParameterValue {
    return this.categorySpecificParams.get(paramName) ?? null;
  }

  public getParamStringValue(paramName: string): string | undefined {
    const value = this.categorySpecificParams.get(paramName);
    if (value === undefined) {
      return undefined;
    }
    if (typeof value !== "string") {
      throw new Error("DEV: Should not happend.");
    }
    return value;
  }

  /** Used only in watch params. Remove when watches will be moved to new API. */
  public getParams(): Map<string | number, DatabaseFilterParameterValue> {
    return this.categorySpecificParams;
  }

  public nextPage(): void {
    this.page++;
  }

  public previousPage(): void {
    this.page--;
  }

  public setPage(newCurrentPage: number): void {
    this.page = newCurrentPage;
  }
}

/**
 * Returns filter fields definitions. Useful for filter HTML rendering.
 **/
export function getFilterFields(category: Category): CatalogueFilterField[] {
  return fields.get(category.getId()) ?? [];
}

/**
 * Represents single HTML field in catalogue filter.
 * Wraps appereance and functions of the HTML element.
 */
export class CatalogueFilterField {
  private placeholder: string;
  private options: CatalogueFilterDropdownOption[];

  constructor(private type: FieldType, private label: string, private name: string) {}

  public getType(): FieldType {
    return this.type;
  }

  public getLabel(): string {
    return this.label;
  }

  public getName(): string {
    return this.name;
  }

  public setOptions(options: CatalogueFilterDropdownOption[]): this {
    this.options = options;
    return this;
  }

  public getOptions(): CatalogueFilterDropdownOption[] {
    return this.options;
  }

  public setPlaceholder(placeholder: string): this {
    this.placeholder = placeholder;
    return this;
  }

  public getPlaceholder(): string {
    return this.placeholder;
  }

  public isType(type: FieldType): boolean {
    return this.getType() === type;
  }
}

const VinylCountries = [
  "Československo", // Keep comments to preserve line breaks (prettier fix).
  "Spojené státy americké (USA)", //
  "Velká Británie", //
  "Evropa", //
  "Česká republika", //
  "Německo", //
  "Francie", //
  "Slovensko", //
  "Velká Británie a Evropa", //
  "Kanada", //
  "Svět", //
  "Nizozemsko", //
  "Spojené státy americké a Evropa", //
  "Spojené státy americké a Kanada", //
  "Velká Británie, Evropa a Spojené státy americké", //
  "Itálie", //
  "Spojené státy americké, Kanada a Evropa", //
  "Švédsko", //
  "Austrálie", //
  "Velká Británie a Spojené státy americké", //
  "Španělsko", //
  "Belgie", //
  "Brazílie", //
  "Japonsko", //
  "Skandinávie", //
  "Velká Británie a Irsko", //
  "Německo, Rakousko a Švýcarsko", //
  "Dánsko", //
  "Německá demokratická republika (NDR)", //
  "Finsko", //
  "Spojené státy americké, Kanada a Velká Británie", //
  "Švýcarsko", //
  "SSSR", //
  "Rusko", //
  "Norsko", //
  "Rakousko", //
  "Benelux", //
  "Portugalsko", //
  "Polsko", //
  "Austrálie a Nový Zéland", //
  "Severní Amerika (včetně Mexika)", //
  "Francie a Benelux", //
  "Hongkong", //
  "Argentina", //
  "Nový Zéland", //
  "Velká Británie a Francie", //
  "Australasie", //
  "Jižní Korea", //
  "Bulharsko", //
  "Chile", //
  "Řecko", //
  "Island", //
  "Irsko", //
  "Kolumbie", //
  "Litva", //
  "Jižní Afrika", //
  "Čína", //
  "Kostarika", //
  "Estonsko", //
  "Německo a Švýcarsko", //
  "Maďarsko", //
  "Mexiko", //
  "Maroko", //
  "Rumunsko", //
  "Singapur", //
  "Slovinsko", //
  "Tchaj-wan", //
  "Velká Británie a Německo", //
  "Velká Británie, Evropa a Japonsko", //
  "Uruguay", //
  "Jugoslávie", //
  "Indie", //
  "Peru", //
  "Ekvádor", //
  "Izrael", //
  "Venezuela", //
  "Turecko", //
  "Jamajka", //
  "Chorvatsko", //
  "Nigérie", //
  "Rhodesie", //
  "Ukrajina", //
  "Andorra", //
  "Česká republika a Slovensko", //
  "Lotyšsko", //
  "Libanon", //
  "Lucembursko", //
  "Severní a Jižní Amerika", //
  "Panama", //
  "Trinidad a Tobago", //
];

const VinylFormats = [
  "LP", // Keep comments to preserve line breaks (prettier fix).
  '7"', //
  "45 RPM", //
  "Album", //
  "Single", //
  '12"', //
  "Stereo", //
  "33 ⅓ RPM", //
  "Compilation", //
  "Promo", //
  "EP", //
  "Limited Edition", //
  "Reissue", //
  "Mono", //
  "White Label", //
  '10"', //
  "Repress", //
  "Unofficial Release", //
  "Test Pressing", //
  "Numbered", //
  "Single Sided", //
  "Maxi-Single", //
  "Remastered", //
  "Box Set", //
  "Club Edition", //
  "Styrene", //
  "Picture Disc", //
  "78 RPM", //
  "CD", //
  "Special Edition", //
  "Mini-Album", //
  "All Media", //
  "Misprint", //
  "Jukebox", //
  "Sampler", //
  "Deluxe Edition", //
  "Transcription", //
  "Record Store Day", //
  "Etched", //
  "Mixed", //
  "Quadraphonic", //
  "Mispress", //
  "Partially Mixed", //
  "Shape", //
  '8"', //
  "Advance", //
  "DVD", //
];

const BoardgamesLanguages = [
  "čeština", //
  "angličtina", //
  "slovenština", //
  "afrikánština", //
  "albánština", //
  "arabština", //
  "arménština", //
  "barmština", //
  "baskičtina", //
  "bosenština", //
  "bretonština", //
  "bulharština", //
  "běloruština", //
  "cherokee", //
  "chorvatština", //
  "dánština", //
  "esperanto", //
  "estonština", //
  "faerština", //
  "filipínština", //
  "finština", //
  "francouzština", //
  "galicijština", //
  "gruzínština", //
  "hebrejština", //
  "hindština", //
  "holandština", //
  "indonéština", //
  "irština", //
  "islandština", //
  "italština", //
  "japonština", //
  "katalánština", //
  "korejština", //
  "latinština", //
  "litevština", //
  "lotyšština", //
  "lucemburština", //
  "makedonština", //
  "malajština", //
  "maltština", //
  "mapudungun", //
  "maďarština", //
  "norština", //
  "němčina", //
  "perština", //
  "polština", //
  "portugalština", //
  "rumunština", //
  "ruština", //
  "rétorománština", //
  "sališské jazyky", //
  "sardinština", //
  "skotská gaelština", //
  "slovinština", //
  "srbština", //
  "tamilština", //
  "thajština", //
  "turečtina", //
  "ukrajinština", //
  "velština", //
  "vietnamština", //
  "znakové jazyky", //
  "ázerbájdžánština", //
  "čínština", //
  "řečtina", //
  "španělština", //
  "švédština", //
];

const fields = new Map<number, CatalogueFilterField[]>();

fields.set(CategoryId.vinyl, [
  new CatalogueFilterField(FieldType.userInput, "Název&nbsp;/&nbsp;Cat#&nbsp;/&nbsp;Kód", "identification"),

  new CatalogueFilterField(FieldType.userInput, "Interpret", "interpret"),

  new CatalogueFilterField(FieldType.dropdown, "Rok vydání", "year").setOptions(
    generateRangeArray(VinylsFilterYearFrom, VinylsFilterYearTo).map((year) => {
      return { name: year, id: year };
    })
  ),

  new CatalogueFilterField(FieldType.dropdown, "Země", "country").setOptions(
    VinylCountries.map((country) => {
      return { name: country, id: country };
    })
  ),

  new CatalogueFilterField(FieldType.dropdown, "Formát", "format").setOptions(
    VinylFormats.map((format) => {
      return { name: format, id: format };
    })
  ),
]);

const BoardGamesLabelName = translate("views.catalogue.filter.boardGames.name");
const BoardGamesLabelAuthors = translate("views.catalogue.filter.boardGames.developers");
const BoardGamesLabelLanguages = translate("views.catalogue.filter.boardGames.languages");
fields.set(CategoryId.boardgame, [
  new CatalogueFilterField(FieldType.userInput, BoardGamesLabelName, "identification").setPlaceholder(BoardGamesLabelName), //
  new CatalogueFilterField(FieldType.userInput, BoardGamesLabelAuthors, "developer").setPlaceholder(BoardGamesLabelAuthors),
  new CatalogueFilterField(FieldType.dropdown, BoardGamesLabelLanguages, "Language")
    .setOptions(BoardgamesLanguages.map((lang) => ({ name: lang, id: lang }))) //
    .setPlaceholder(BoardGamesLabelLanguages), //
]);

// Lego is old-api category. So far, we use only CatalogueFilterField.name parameter.
fields.set(CategoryId.lego, [
  new CatalogueFilterField(FieldType.dropdown, "Set", "nameOrNumber"), //
  new CatalogueFilterField(FieldType.dropdown, "Téma", "theme"), //
  new CatalogueFilterField(FieldType.dropdown, "Rok vydání", "year"), //
]);

// Lego minifigs is old-api category. So far, we use only CatalogueFilterField.name parameter.
fields.set(CategoryId.legoMinifigs, [
  new CatalogueFilterField(FieldType.userInput, "Najít figurku", "q"), //
]);

export function createYearOptions(): LegoFilterDropdownOption[] {
  const from = 1949;
  const to = new Date().getFullYear();
  const options = [];

  for (let year = from; year <= to; year++) {
    options.push({ name: year.toString(), id: year });
  }

  return options;
}

export async function createThemesOptions(): Promise<LegoFilterDropdownOption[]> {
  const ThemesDelimiter = " » ";

  return Themes.getTree().then((tree) => {
    const options: LegoFilterDropdownOption[] = [];

    tree.forEach((theme: LegoTheme) => {
      if (theme.hasSubthemes()) {
        theme.getSubthemes().forEach((subtheme) => {
          options.push({
            name: theme.getName() + ThemesDelimiter + subtheme.getName(),
            id: subtheme.getId(),
          });
        });
      } else {
        options.push({
          name: theme.getName(),
          id: theme.getId(),
        });
      }
    });

    return options;
  });
}
