import { CurrencySymbolPosition } from "./currencyTypes";

class Currency {
  private code: string;
  private symbol: string;
  private symbolPosition: CurrencySymbolPosition;
  private useSpaceSeparator: boolean;
  private decimalSeparator: string;
  private decimalPrecision: number;
  private thousandsSeparator: string;
  private availableForSwitchSelector: boolean;
  private ratio: Map<number, number> = new Map();

  constructor(private id: number) {}

  public getId(): number {
    return this.id;
  }

  public setCode(code: string): void {
    this.code = code;
  }

  public getCode(): string {
    return this.code;
  }

  public setSymbol(symbol: string): void {
    this.symbol = symbol;
  }

  public getSymbol(): string {
    return this.symbol;
  }

  public setSymbolPosition(position: CurrencySymbolPosition): void {
    this.symbolPosition = position;
  }

  public isSymbolPositionLeft(): boolean {
    return this.symbolPosition === CurrencySymbolPosition.left;
  }

  public setIsSpaceSeparator(isSpaceSeparator: boolean): void {
    this.useSpaceSeparator = isSpaceSeparator;
  }

  public isSpaceSeparator(): boolean {
    return this.useSpaceSeparator;
  }

  public setDecimalSeparator(decimalSeparator: string): void {
    this.decimalSeparator = decimalSeparator;
  }

  public getDecimalSeparator(): string {
    return this.decimalSeparator;
  }

  public setDecimalPrecision(decimalPrecision: number): void {
    this.decimalPrecision = decimalPrecision;
  }

  public getDecimalPrecision(): number {
    return this.decimalPrecision;
  }

  public setThousandsSeparator(thousandsSeparator: string): void {
    this.thousandsSeparator = thousandsSeparator;
  }

  public getThousandsSeparator(): string {
    return this.thousandsSeparator;
  }

  public setIsAvailableForSwitchSelector(isAvailableForSwitchSelector: boolean): void {
    this.availableForSwitchSelector = isAvailableForSwitchSelector;
  }

  public isAvailableForSwitchSelector(): boolean {
    return this.availableForSwitchSelector;
  }

  public addRatio(currencyId: number, ratio: number): void {
    this.ratio.set(currencyId, ratio / 1000);
  }

  public getRatio(currencyId: number): number | null {
    const found = this.ratio.get(currencyId);
    return found ?? null;
  }
}

export { Currency };
