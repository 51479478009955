export default {
  collection: {
    makeItemPublicDialog: {
      header: "Zveřejnění předmětu",
      text: "Změnou stavu zveřejníš předmět. Ten se ti pak propíše do Veřejného profilu <strong>bez tvých soukromých parametrů</strong>. Zde si můžeš přidat poznámku či požadovanou cenu k prodeji a profil se sbírkou sdílet.",
      cancel: "Ponechat",
      confirm: "Zveřejnit",
      dontShowNextTime: "Příště nezobrazovat",
      confirmMessage: "Předmět zveřejněn.",
    },
    makeItemPublicDialogForPrivateProfile: {
      header: "Zveřejnění předmětu & profilu",
      text: "Chystáš se zveřejnit předmět, tvůj profil je ale nastavený jako neveřejný. Se zveřejněním předmětu se ti <strong>automaticky zveřejní i profil</strong>. Jsi si jistý?",
      cancel: "Ponechat",
      confirm: "Zveřejnit",
      confirmMessage: "Předmět zveřejněn.",
    },
    makeItemPrivateDialog: {
      header: "Odzveřejnění předmětu",
      text: "Pozor, předmět zmizí z veřejného profilu. Najdete ho ve své sbírce.",
      cancel: "Ponechat",
      confirm: "Odzveřejnit",
      dontShowNextTime: "příště nezobrazovat",
      confirmMessage: "Předmět odzveřejněn.",
    },
    itemList: {
      owner: "Sběratel",
      pieces: "Množství",
      piecesUnit: "ks",
      offeringFor: "Nabízím za",
      noNote: "Bez poznámky",
      detailButton: "Detail předmětu",
      visibilityLabel: "Zobrazení předmětu",
      publishDate: "Vystaveno",
    },
  },
};
