import { RawLegoSetData } from "@/types";
import { createCataloguePrice } from "../database/cataloguePriceFactory";
import { LegoSet } from "./LegoSet";
import { createLegoTheme } from "./legoThemeFactory";
import { createImage } from "../images";

export function createLegoSet(data: RawLegoSetData): LegoSet {
  const set = new LegoSet(data.id, data.name, data.number);

  set.partsCount = data.partsCount;
  set.year = data.year;
  set.weight = data.weight;
  set.dimensions = data.dimensions;

  data.images?.forEach((rawImageData) => {
    set.addImage(createImage(rawImageData, set.getName()));
  });

  if (data.prices) {
    data.prices.forEach((rawPriceData) => {
      set.addPrice(createCataloguePrice(rawPriceData));
    });
  }

  if (data.theme) {
    set.theme = createLegoTheme(data.theme);
  }

  return set;
}
