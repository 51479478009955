import { Layout } from "@/types";

export enum ErrorRouteName {
  error404 = "error404",
}

export const errorPagesRoutes = [
  {
    name: ErrorRouteName.error404,
    path: "/:pathMatch(.*)",
    components: { mainContent: () => import("@/views/errorPages/ErrorNotFound.vue") },
    meta: {
      public: true,
      title: "404 Stránka nenalezena",
      layout: { name: Layout.error },
    },
  },
];
