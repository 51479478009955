import { RawWatchParameterOptionData } from "@/types";
import { Parameters } from "./WatchParameters";

export class WatchParameter {
  public options: RawWatchParameterOptionData[] = [];
  public allowCustomValues = false;

  constructor(public id: number, public label: string) {}

  public getId(): number {
    return this.id;
  }

  public setOptions(options: RawWatchParameterOptionData[]): void {
    this.options = options;
  }

  public setAllowCustomValues(allow: boolean): void {
    this.allowCustomValues = allow;
  }

  public getOptions(): RawWatchParameterOptionData[] {
    return this.options;
  }

  /**
   * Must be arrow syntax to preserve context in this.getId() while this method is used as a lazyloader for DropdownSelect.vue.
   */
  public loadOptions = async (optionId: number | null = null) => {
    if (this.options.length > 0) {
      return new Promise((resolve) => {
        if (optionId) {
          resolve(this.getOptions().find((option) => option.id === optionId));
        } else {
          resolve(this.getOptions());
        }
      });
    } else {
      if (optionId) {
        return Parameters.getOption(optionId);
      } else {
        return Parameters.getOptions(this.getId()).then((options) => {
          this.options = options;
          return this.getOptions();
        });
      }
    }
  };

  public async getOption(optionId: number): Promise<RawWatchParameterOptionData> {
    const found = this.getOptions().find((option) => option.id === optionId);

    if (found) {
      return new Promise((resolve) => resolve(found));
    } else {
      return Parameters.getOption(optionId);
    }
  }
}
