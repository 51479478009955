import { thirdPartyLogin, establishUserForThisSession, UserNotEstablishedException } from "@/services/user";
import { Events } from "@/types";
import { AccountType, ApiResponse_ThirdPartyLoginAuthentication, LoginProvider, ThirdPartyLoginStatus } from "./internalUserTypes";
import { router } from "@/router";
import { createProfileInitializationRouteLink } from "@/utilities/routerHelpers";
import { trackNewRegistrationEvent } from "../marketingHelpers";
import { redirectAfterLogin } from "./loginHelpers";

const FacebookAppId = import.meta.env.VITE_FB_APP_ID;
const RequiredScope = "public_profile,email";
let isInitialized = false;
let subscribeNewsletter = false;

enum FacebookLoginStatus {
  unknown = "unknown",
  notAuthorized = "not_authorized",
  connected = "connected",
}

export function login(registerNewsletter: boolean): void {
  subscribeNewsletter = registerNewsletter;

  initFb().then(() => {
    window.FB.getLoginStatus((response) => {
      const status = response.status;

      if (status === FacebookLoginStatus.unknown || status === FacebookLoginStatus.notAuthorized) {
        openDialog();
      } else if (status === FacebookLoginStatus.connected) {
        loginUser();
      }
    });
  });
}

export function logout(): void {
  initFb().then(() => {
    FB.logout();
  });
}

function openDialog(): void {
  FB.login(
    (response) => {
      if (response.status === FacebookLoginStatus.connected) {
        loginUser();
      } else {
        window.eventBus.emit(Events.toastError, "Přihlášení se nezdařilo.");
      }
    },
    {
      scope: RequiredScope,
    }
  );
}

function loginUser(): void {
  window.eventBus.emit(Events.showTextOverlay, { text: "Kontroluji údaje..." });

  getUserToken()
    .then((token: string) => {
      thirdPartyLogin(token, subscribeNewsletter, LoginProvider.facebook).then((response: ApiResponse_ThirdPartyLoginAuthentication) => {
        window.eventBus.emit(Events.hideTextOverlay);

        if (response.status === ThirdPartyLoginStatus.ok) {
          if (response.isFirstLogin) {
            trackNewRegistrationEvent(response.user.id, AccountType.facebook);
          }

          establishUserForThisSession(response.token, response.user)
            .then(() => redirectAfterLogin())
            .catch((e: unknown) => {
              if (e instanceof UserNotEstablishedException) {
                const redirect = createProfileInitializationRouteLink();
                router.push(redirect);
              } else {
                throw e;
              }
            });
        } else {
          window.eventBus.emit(Events.toastError, response.message);
        }
      });
    })
    .catch(() => {
      window.eventBus.emit(Events.toastError, "Nepodařilo se získat user token.");
    });
}

async function getUserToken(): Promise<string> {
  return new Promise((resolve, reject) => {
    FB.getLoginStatus((response) => {
      const token = response.authResponse.accessToken;
      if (token) {
        resolve(token);
      } else {
        reject();
      }
    });
  });
}

function initFb(): Promise<void> {
  return new Promise((resolve) => {
    if (isInitialized) {
      resolve();
    } else {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: FacebookAppId,
          cookie: true,
          xfbml: true,
          version: "v8.0",
        });

        isInitialized = true;

        resolve();
      };

      if (typeof window.FB === "undefined") {
        const id = "facebook-jssdk";
        const tag = "script";

        const fjs = document.getElementsByTagName(tag)[0];
        if (document.getElementById(id)) {
          return;
        }

        const js: HTMLScriptElement = document.createElement(tag);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode?.insertBefore(js, fjs);
      }
    }
  });
}
