import { API } from "@/services/api/API";
import { API as API2 } from "@/services/api2";
import { Category } from "./Category";
import { Parameter } from "./parameters/Parameter";
import { SimpleCategory } from "./SimpleCategory";
import { CategoryCovers, RawParameterValueData } from "@/types";
import { requireUser } from "@/services/user";

enum SortOption {
  byCollectionContent = "byCollectionContent",
  byDefaultOrder = "bySystemOrder",
}

interface RawSimpleCategoryData {
  id: number;
  name: string;
}

interface RawParameterData {
  id: number;
  name: string;
  customInput: 0 | 1;
  conditionParameter: number | null;
  conditionValue: number | null;
  values: RawParameterValueData[];
}

interface RawCategoryData {
  id: number;
  url: string;
  name: string;
  isSearchable: 0 | 1;
  parameters: RawParameterData[];
}

const OldApiUrl = "collection/categories/";
const NewApiUrl = import.meta.env.VITE_API_COLLECTOR_URL + "collection/category/";

// Promises cache
let getAllSortByCollectionContentPromise: Promise<Category[]>;
let getAllSortBySystemOrderPromise: Promise<Category[]>;
let getHavingArticlePromise: Promise<Category[]>; // We don't expect these will be changed very often.

export const Categories = {
  getHavingArticles: async function (): Promise<Category[]> {
    if (!getHavingArticlePromise) {
      getHavingArticlePromise = API.get(OldApiUrl + "get-having-articles", false).then((response) => {
        return response.categories.map((rawData: RawCategoryData) => createCategory(rawData));
      });
    }

    return getHavingArticlePromise;
  },

  getAll: async function (sortForUser?: boolean): Promise<Category[]> {
    const sort = sortForUser ? SortOption.byCollectionContent : SortOption.byDefaultOrder;

    if (sort === SortOption.byCollectionContent) {
      if (typeof getAllSortByCollectionContentPromise === "undefined") {
        const data = { sort };

        getAllSortByCollectionContentPromise = API.post(OldApiUrl + "get-all", data).then((response) => {
          return createCategoriesArray(response);
        });
      }

      return getAllSortByCollectionContentPromise;
    } else {
      if (typeof getAllSortBySystemOrderPromise === "undefined") {
        getAllSortBySystemOrderPromise = API.post(OldApiUrl + "get-all-public", {}, {}, false).then((response) => {
          return createCategoriesArray(response);
        });
      }

      return getAllSortBySystemOrderPromise;
    }
  },

  getMy: async function (): Promise<Category[]> {
    return API.get(OldApiUrl + "get-my").then((response) => {
      return createCategoriesArray(response);
    });
  },

  getMyTopUsed: async function (): Promise<SimpleCategory[]> {
    const url = NewApiUrl + "my-top-used";
    return API2.get<RawSimpleCategoryData[]>(url).then((response) => {
      return response.data.map((data) => new SimpleCategory(data.id, data.name));
    });
  },

  getByUrl: async function (slug: string): Promise<Category> {
    const user = requireUser();
    const token = user.getToken();
    const url = `${token}/${OldApiUrl}get-by-url/${slug}`;
    return API2.get<RawCategoryData>(url).then((response) => createCategory(response.data));
  },

  getById: async function (id: number): Promise<Category> {
    return new Promise((resolve) => {
      this.getAll().then((categories) => {
        const found = categories.find((category) => category.getId() === id);
        if (found) {
          resolve(found);
        }
      });
    });
  },
};

/**
 * Factories
 */

export function createCategory(data: RawCategoryData): Category {
  const coverFile = CategoryCovers.get(data.id);
  const cover = coverFile ? "/images/categories/" + coverFile + ".jpg" : undefined;

  const category = new Category(data.id, data.url, data.name, data.isSearchable, cover);

  if (data.parameters) {
    category.parameters = data.parameters.map((paramData) => {
      return new Parameter(paramData);
    });
  }

  return category;
}

function createCategoriesArray(data: RawCategoryData[]): Category[] {
  return data.map((rawData) => createCategory(rawData));
}
