import { API } from "@/services/api2";
import { Currency } from "./Currency";
import { createCurrency } from "./currencyFactory";
import { RawCurrencyListData } from "./currencyTypes";

const url = import.meta.env.VITE_API_PUBLIC_URL + "currency";
let getAllRequestCache: Promise<Currency[]> | null = null;

export const Currencies = {
  async getAll(): Promise<Currency[]> {
    if (!getAllRequestCache) {
      getAllRequestCache = API.getPublic<RawCurrencyListData[]>(url).then((response) => {
        return response.data.map((rawData) => createCurrency(rawData));
      });
    }

    return getAllRequestCache;
  },

  async getById(id: number): Promise<Currency> {
    return this.getAll().then((currencies: Currency[]) => {
      const found = currencies.find((currency) => currency.getId() === id);

      if (!found) {
        throw new Error("DEV: Currency with id " + id + " not found.");
      }

      return found;
    });
  },
};
