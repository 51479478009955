import { ContentWidth, Layout } from "@/types";
import { translate } from "@/services/translation";
import { RouteRecordRaw } from "vue-router";
import { AppRouteName } from "./appRoutes";

export enum PublicRouteName {
  registration = "registrationNew",
  registrationEmailVerified = "registrationEmailVerified",
  login = "login",
  passwordRestore = "forgottenPassword",
  profile = "publicProfile",
  publicHomepage = "homepage",
  publicItemDetail = "publicItemCard",
  about = "about",
  gdpr = "gdpr",
  magazine = "articlesList",
  article = "article",
  contacts = "contacts",
  legalInformation = "legalInformation",
  books = "booksDownload",
  publicItemsList = "publicItemsList",
}

export const publicRoutes: RouteRecordRaw[] = [
  /**
   * Old-site URLs redirects
   */
  {
    path: "/tenisky",
    redirect: "/",
  },
  {
    path: "/alkohol",
    redirect: "/",
  },
  {
    path: "/umeni",
    redirect: "/",
  },

  /**
   * Login
   */

  {
    name: PublicRouteName.login,
    path: "/login",
    components: { mainContent: () => import("@/views/loginAndRegistration/LoginPage.vue") },
    meta: {
      public: true,
      title: "Přihlášení",
      layout: { name: Layout.loginAndRegistration },
      showLoginMenu: true,
    },
  },

  /**
   * Public simple views
   */

  {
    name: PublicRouteName.publicHomepage,
    path: "/",
    components: { mainContent: () => import("@/views/public/HomepagePublic.vue") },
    meta: {
      public: true,
      title: "Objevuj svět sběratelů",
      layout: {
        name: Layout.public,
        contentWidth: ContentWidth.full,
      },
      redirectWhenLoggedIn: { name: AppRouteName.dashboard },
    },
  },
  {
    path: "/lego",
    components: { mainContent: () => import("@/views/public/LandingPageLego.vue") },
    meta: {
      public: true,
      title: "Lego®",
      layout: {
        name: Layout.public,
        contentWidth: ContentWidth.full,
      },
    },
  },
  {
    path: "/hodinky",
    components: { mainContent: () => import("@/views/public/LandingPageWatch.vue") },
    meta: {
      public: true,
      title: "Hodinky",
      layout: {
        name: Layout.public,
        contentWidth: ContentWidth.full,
      },
    },
  },
  {
    name: PublicRouteName.gdpr,
    path: "/ochrana-osobnich-udaju",
    components: { mainContent: () => import("@/views/TermsAndCondition.vue") },
    meta: {
      public: true,
      title: translate("views.termsAndConditions.heading"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },
  {
    name: PublicRouteName.legalInformation,
    path: "/pravni-informace",
    components: { mainContent: () => import("@/views/public/LegalInformation.vue") },
    meta: {
      public: true,
      title: translate("views.legalInformation.heading"),
      layout: { name: Layout.public },
    },
  },
  {
    name: PublicRouteName.contacts,
    path: "/kontakty",
    components: { mainContent: () => import("@/views/ContactPage.vue") },
    meta: {
      public: true,
      title: "Kontakty",
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },
  {
    name: PublicRouteName.about,
    path: "/o-nas",
    components: { mainContent: () => import("@/views/AboutUs.vue") },
    meta: {
      public: true,
      title: "O nás",
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },
  {
    name: PublicRouteName.books,
    path: "/prirucky",
    components: { mainContent: () => import("@/views/BooksDownload.vue") },
    meta: {
      public: true,
      title: "Příručky",
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
      breadcrumbs: [
        {
          text: "Příručky",
        },
      ],
    },
  },

  /**
   * Magazine
   */
  {
    path: "/magazin",
    children: [
      {
        name: PublicRouteName.article,
        path: ":id(\\d+)-:url",
        components: { mainContent: () => import("@/views/ArticleDetail.vue") },
        meta: {
          public: true,
          title: "Magazín",
          layout: {
            name: Layout.public,
            contentWidth: ContentWidth.full,
          },
          layoutLoggedIn: {
            name: Layout.app,
            contentWidth: ContentWidth.middle,
          },
        },
      },
      {
        name: PublicRouteName.magazine,
        path: ":category?",
        components: { mainContent: () => import("@/views/ArticlesList.vue") },
        meta: {
          public: true,
          title: "Magazín",
          layout: { name: Layout.public },
          layoutLoggedIn: { name: Layout.app },
          breadcrumbs: [{ text: translate("views.magazine.breadcrumb") }],
        },
      },
    ],
  },

  /**
   * Registration
   */

  {
    path: "/registrace",
    children: [
      {
        path: "",
        name: PublicRouteName.registration,
        components: { mainContent: () => import("@/views/loginAndRegistration/RegistrationPage.vue") },
        meta: {
          public: true,
          title: "Nová registrace",
          layout: { name: Layout.loginAndRegistration },
          showLoginMenu: true,
        },
      },
      {
        name: PublicRouteName.registrationEmailVerified,
        path: "overeni-emailu/:hash",
        components: { mainContent: () => import("@/views/loginAndRegistration/EmailVerified.vue") },
        meta: {
          public: true,
          title: "Ověření e-mailu",
          layout: { name: Layout.loginAndRegistration },
        },
      },
    ],
  },

  /**
   * Password restore
   */

  {
    path: "/zapomenute-heslo",
    children: [
      {
        path: "",
        name: PublicRouteName.passwordRestore,
        components: { mainContent: () => import("@/views/loginAndRegistration/PasswordRestoreRequest.vue") },
        meta: {
          public: true,
          title: "Zapomenuté heslo",
          layout: { name: Layout.loginAndRegistration },
          showLoginMenu: true,
        },
      },
      {
        path: ":hash",
        components: { mainContent: () => import("@/views/loginAndRegistration/PasswordRestore.vue") },
        meta: {
          public: true,
          title: "Obnova hesla",
          layout: { name: Layout.loginAndRegistration },
        },
      },
    ],
  },
  {
    path: "/obnova-hesla/:hash",
    components: { mainContent: () => import("@/views/loginAndRegistration/PasswordRestore.vue") },
    meta: {
      public: true,
      title: "Obnova hesla",
      layout: { name: Layout.loginAndRegistration },
    },
  },

  /**
   * Profiles
   */
  {
    name: PublicRouteName.publicItemDetail,
    path: "/collector/:nickname/:slug/:itemId",
    components: { mainContent: () => import("@/views/public/PublicItemDetail.vue") },
    meta: {
      public: true,
      title: "Veřejný předmět",
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },
  {
    name: PublicRouteName.profile,
    path: "/collector/:nickname",
    components: { mainContent: () => import("@/views/UserProfile.vue") },
    meta: {
      public: true,
      title: "Profil",
      layout: {
        name: Layout.public,
        contentWidth: ContentWidth.narrow,
      },
      layoutLoggedIn: {
        name: Layout.app,
        contentWidth: ContentWidth.middle,
      },
      breadcrumbs: [{ text: translate("views.userProfile.breadcrumbs.public") }],
    },
  },

  /**
   * Public items listing
   */
  {
    name: PublicRouteName.publicItemsList,
    path: "/predmety-sberatelu",
    components: { mainContent: () => import("@/views/PublicCollectionItemsList.vue") },
    meta: {
      public: true,
      title: translate("views.publicItemsList.title"),
      layout: {
        name: Layout.public,
        contentWidth: ContentWidth.wide,
      },
      layoutLoggedIn: {
        name: Layout.app,
        contentWidth: ContentWidth.wide,
      },
      breadcrumbs: [
        {
          text: translate("views.publicItemsList.title"),
        },
      ],
    },
  },
];
