import { ComponentPublicInstance } from "vue";
import { getUser } from "./user";
import { API, Response401Exception } from "@/services/api2";
import { BuildMode } from "@/types";

interface LogErrorApiData {
  name: string;
  message: string;
  stack: string | null;
  lifecycleHook: string;
  user: number | null;
  url: string;
}

const LOG_URL = import.meta.env.VITE_API_PUBLIC_URL + "error";

const logToBeckend = async (data: LogErrorApiData): Promise<void> => {
  const user = await getUser();

  if (user) {
    data.user = user.getId();
  }

  API.putPublic(LOG_URL, {}, data);
};

export function handleError(error: unknown, component: ComponentPublicInstance | null, lifecycleHook: string): void {
  if (error instanceof Response401Exception) {
    return; // It's okay -> api clients should take care about redirect to login page. Not a real error.
  }

  const mode = import.meta.env.MODE;
  if (mode === BuildMode.production) {
    const e = error as Error;

    const data = <LogErrorApiData>{
      name: e.name,
      message: e.message,
      stack: e.stack ?? null,
      lifecycleHook: lifecycleHook.toString(),
      user: null, // User will be added in async function.
      url: window.location.href,
    };

    logToBeckend(data);
  }

  throw error;
}
