import { Breadcrumb } from "@/composables";

interface state {
  breadcrumbs: Breadcrumb[];
}

export default {
  state: {
    breadcrumbs: [],
  },
  mutations: {
    addBreadcrumb(state: state, breadcrumb: Breadcrumb) {
      state.breadcrumbs.push(breadcrumb);
    },
    setBreadcrumbs(state: state, breadcrumbs: Breadcrumb[]) {
      state.breadcrumbs = breadcrumbs;
    },
    clearBreadcrumbs(state: state) {
      state.breadcrumbs = [];
    },
  },
  getters: {
    getBreadcrumbs: (state: state): Breadcrumb[] => {
      return state.breadcrumbs;
    },
  },
};
