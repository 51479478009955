<template>
  <div class="icon">
    <FontAwesomeIcon :icon="iconParams" />
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faAlignLeft as falAlignLeft, //
  faArrowLeftRotate as falArrowLeftRotate, //
  faArrowsUpDown as falArrowsUpDown, //
  faBasketShopping as falBasketShopping, //
  faBars as falBars, //
  faBell as falBell, //
  faBlockBrick as falBlockBrick, //
  faBook as falBook, //
  faBookUser as falBookUser, //
  faCalendar as falCalendar, //
  faCamera as falCamera, //
  faCartShopping as falCartShopping, //
  faCircleInfo as falCircleInfo, //
  faCircleXmark as falCircleXmark, //
  faClipboardList as falClipboardList, //
  faCoin as falCoin, //
  faCoins as falCoins, //
  faCommentsDollar as falCommentsDollar, //
  faCopy as falCopy, //
  faEye as falEye, //
  faEyeSlash as falEyeSlash, //
  faEnvelope as falEnvelope, //
  faFileInvoice as falFileInvoice, //
  faFolder as falFolder, //
  faHeading as falHading, //
  faHashtag as falHashtag, //
  faChampagneGlasses as falChampagneGlasses, //
  faChartLine as falChartLine, //
  faChartLineUp as falChartLineUp, //
  faCheck as falCheck, //
  faChevronLeft as falChevronLeft, //
  faChevronDown as falChevronDown, //
  faChevronRight as falChevronRight, //
  faChevronsUp as falChevronsUp, //
  faImage as falImage, //
  faInfoCircle as falInfoCircle, //
  faLaptopMobile as falLaptopMobile, //
  faListUl as falListUl, //
  faMagnifyingGlass as falMagnifyingGlass, //
  faMoneyBillWave as falMoneyBillWave, //
  faNewspaper as falNewspaper, //
  faNoteSticky as falNoteSticky, //
  faPencil as falPencil, //
  faPlus as falPlus, //
  faRectangle as falRectangle, //
  faRotateForward as falRotateForward, //
  faServer as falServer, //
  faStar as falStar, //
  faStopwatch as falStopwatch, //
  faSquarePollVertical as falSquarePollVertical, //
  faTableList as falTableList, //
  faTrash as falTrash, //
  faUser as falUser, //
  faUsers as falUsers, //
  faUserSecret as falUserSecret, //
  faWatch as falWatch, //
  faXmark as falXmark, //
} from "@fortawesome/pro-light-svg-icons";

import {
  faArrowDownArrowUp as fasArrowDownArrowUp, //
  faArrowLeftToLine as fasArrowLeftToLine, //
  faArrowsRotate as fasArrowsRotate, //
  faBars as fasBars, //
  faBell as fasBell, //
  faBook as fasBook, //
  faCalendar as fasCalendar, //
  faCamera as fasCamera, //
  faCaretUp as fasCaretUp, //
  faCartShopping as fasCartShopping, //
  faCircleExclamation as fasCircleExclamation, //
  faCircleCheck as fasCircleCheck, //
  faCircleInfo as fasCircleInfo, //
  faCircleQuestion as fasCircleQuestion, //
  faCoin as fasCoin, //
  faCoins as fasCoins, //
  faComment as fasComment, //
  faCopy as fasCopy, //
  faDownload as fasDownload, //
  faEllipsisVertical as fasEllipsisVertical, //
  faEye as fasEye, //
  faEyeSlash as fasEyeSlash, //
  faEnvelope as fasEnvelope, //
  faFingerprint as fasFingerprint, //
  faFolderOpen as fasFolderOpen, //
  faGear as fasGear, //
  faGridRound2 as fasGridRound2, //
  faGlobe as fasGlobe, //
  faHouse as fasHouse, //
  faHeart as fasHeart, //
  faChartLineDown as fasChartLineDown, //
  faChartLineUp as fasChartLineUp, //
  faChartPie as fasChartPie, //
  faCheck as fasCheck, //
  faChevronDown as fasChevronDown, //
  faChevronLeft as fasChevronLeft, //
  faChevronRight as fasChevronRight, //
  faChevronUp as fasChevronUp, //
  faImage as fasImage, //
  faInfoCircle as fasInfoCircle, //
  faLinkHorizontal as fasLinkHorizontal, //
  faList as fasList, //
  faListUl as fasListUl, //
  faMagnifyingGlass as fasMagnifyingGlass, //
  faMagnifyingGlassDollar as fasMagnifyingGlassDollar, //
  faMemo as fasMemo, //
  faMemoCircleInfo as fasMemoCircleInfo, //
  faMessageHeart as fasMessageHeart, //
  faMessageSmile as fasMessageSmile, //
  faMinus as fasMinus, //
  faMessageDots as fasMessageDots, //
  faMessageExclamation as fasMessageExclamation, //
  faMoneyBillTrendUp as fasMoneyBillTrendUp, //
  faMoon as fasMoon, //
  faNewspaper as fasNewspaper, //
  faRightToBracket as fasRightToBracket, //
  faPaperPlaneTop as fasPaperPlaneTop, //
  faPen as fasPen, //
  faPencil as fasPencil, //
  faPersonDigging as fasPersonDigging, //
  faPlus as fasPlus, //
  faRotateLeft as fasRotateLeft, //
  faShareFromSquare as fasShareFromSquare, //
  faSliders as fasSliders, //
  faSort as fasSort, //
  faStar as fasStar, //
  faStopwatch as fasStopwatch, //
  faTableList as fasTableList, //
  faTag as fasTag, //
  faTrash as fasTrash, //
  faTreasureChest as fasTreasureChest, //
  faTriangle as fasTriangle, //
  faUser as fasUser, //
  faUserCrown as fasUserCrown, //
  faUserPlus as fasUserPlus, //
  faUserSlash as fasUserSlash, //
  faXmark as fasXmark, //
} from "@fortawesome/pro-solid-svg-icons";

import { faCoin as farCoin, faEye as farEye, faEyeSlash as farEyeSlash, faEnvelope as farEnvelope, faFolderOpen as farFolderOpen, faInfoCircle as farInfoCircle, faLinkHorizontal as farLinkHorizontal, faUserPlus as farUserPlus } from "@fortawesome/pro-regular-svg-icons";

import { faFacebook, faInstagram, faLinkedin, faTiktok, faGoogle, faPinterest, faApple } from "@fortawesome/free-brands-svg-icons";

library.add(
  falAlignLeft,
  falArrowLeftRotate,
  falBasketShopping,
  falArrowsUpDown,
  falBars,
  falBell,
  falBlockBrick,
  falBook,
  falBookUser,
  falCalendar,
  falCamera,
  falCartShopping,
  falCircleInfo,
  falCircleXmark,
  falClipboardList,
  falCoin,
  falCoins,
  falCommentsDollar,
  falCopy,
  falEnvelope,
  falEye,
  falEyeSlash,
  falFileInvoice,
  falFolder,
  falHading,
  falHashtag,
  falChampagneGlasses,
  falChartLine,
  falChartLineUp,
  falCheck,
  falChevronLeft,
  falChevronDown,
  falChevronRight,
  falChevronsUp,
  falImage,
  falInfoCircle,
  falLaptopMobile,
  falListUl,
  falMagnifyingGlass,
  falMoneyBillWave,
  falNewspaper,
  falNoteSticky,
  falPencil,
  falPlus,
  falRectangle,
  falRotateForward,
  falServer,
  falStar,
  falSquarePollVertical,
  falStopwatch,
  falTableList,
  falTrash,
  falUser,
  falUsers,
  falUserSecret,
  falWatch,
  falXmark,

  farCoin,
  farEye,
  farEyeSlash,
  farEnvelope,
  farFolderOpen,
  farInfoCircle,
  farLinkHorizontal,
  farUserPlus,

  fasArrowDownArrowUp,
  fasArrowLeftToLine,
  fasArrowsRotate,
  fasBars,
  fasBell,
  fasBook,
  fasCalendar,
  fasCamera,
  fasCaretUp,
  fasCartShopping,
  fasCircleExclamation,
  fasCircleCheck,
  fasCircleInfo,
  fasCircleQuestion,
  fasCoin,
  fasCoins,
  fasComment,
  fasCopy,
  fasDownload,
  fasEllipsisVertical,
  fasEnvelope,
  fasEye,
  fasEyeSlash,
  fasFingerprint,
  fasFolderOpen,
  fasGear,
  fasGlobe,
  fasGridRound2,
  fasHeart,
  fasHouse,
  fasChartLineDown,
  fasChartLineUp,
  fasChartPie,
  fasCheck,
  fasChevronDown,
  fasChevronLeft,
  fasChevronRight,
  fasChevronUp,
  fasInfoCircle,
  fasImage,
  fasLinkHorizontal,
  fasList,
  fasListUl,
  fasMagnifyingGlass,
  fasMagnifyingGlassDollar,
  fasMemo,
  fasMemoCircleInfo,
  fasMessageHeart,
  fasMessageSmile,
  fasMessageDots,
  fasMessageExclamation,
  fasMinus,
  fasMoneyBillTrendUp,
  fasMoon,
  fasNewspaper,
  fasPaperPlaneTop,
  fasPersonDigging,
  fasPen,
  fasPencil,
  fasPlus,
  fasRightToBracket,
  fasRotateLeft,
  fasShareFromSquare,
  fasSliders,
  fasSort,
  fasStar,
  fasStopwatch,
  fasTableList,
  fasTag,
  fasTrash,
  fasTreasureChest,
  fasTriangle,
  fasUser,
  fasUserCrown,
  fasUserPlus,
  fasUserSlash,
  fasXmark,

  faFacebook,
  faGoogle,
  faInstagram,
  faLinkedin,
  faPinterest,
  faTiktok,
  faApple
);

/**
 * @deprecated This component is obsolete and should not be used in new development. Use /components/basics/Icon.vue
 */
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    weight: {
      type: String,
      default: "light",
      validator: function (value) {
        return ["light", "regular", "solid", "brands"].includes(value);
      },
    },
    brand: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconParams() {
      if (this.brand) {
        return "fa-brands fa-" + this.name;
      } else {
        return "fa-" + this.weight + " fa-" + this.name;
      }
    },
  },
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: 100%;
  height: 100%;
  svg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    * {
      transition: fill 0.3s ease;
    }
  }
}
</style>
