<template>
  <div class="gallery" v-if="isOpen" @click.stop="close">
    <div :class="{ gallery__content: true, 'gallery__content--with-previews': media.length > 1 }">
      <div class="actions">
        <div class="action action--no-border" @click="close">
          <icon name="xmark" weight="solid" />
        </div>

        <slot name="actions" />
      </div>

      <div class="arrow arrow--previous" @click.stop="previous()" v-if="media.length > 1">
        <icon name="chevron-left" weight="solid" />
      </div>
      <div class="arrow arrow--next" @click.stop="next()" v-if="media.length > 1">
        <icon name="chevron-right" weight="solid" />
      </div>

      <div class="counter-wrapper">
        <div class="counter">{{ currentIndex + 1 }}/{{ media.length }}</div>
      </div>

      <div class="image-wrapper">
        <div v-if="typeof currentMedium === 'string'" class="image">
          <img :src="(currentMedium as string)" class="gallery__image" />
        </div>
        <div v-else class="image" v-html="currentMedium.generateHtml(htmlSettings)" />
      </div>
    </div>

    <div class="images-list" v-if="media.length > 1">
      <template v-for="(image, i) in media" :key="i">
        <div :class="{ 'images-list__image-preview': true, current: i === currentIndex }" v-html="createImageHtml(image as GalleryMedium, ImageSize['310x233'])" @click.stop="goToImage(i)" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import Icon from "@/components/IconFontAwesome.vue";
import { Events } from "@/types";
import { computed, onMounted, onUnmounted, ref } from "vue";
import { ImageHtmlSettings, ImageSize } from "@/types";
import { allowBodyScroll, disableBodyScroll } from "@/services/bodyScrollDisable";
import { Image } from "@/services/repositories/images";

export type GalleryMedium = string | Image;

export interface GalleryOpenSettings {
  media: GalleryMedium[];
  index?: number;
  imageHtmlSettings?: ImageSize | ImageHtmlSettings;
}

const currentIndex = ref(0);
const isOpen = ref(false);
const media = ref<GalleryMedium[]>([]);

const currentMedium = computed(() => {
  return media.value[currentIndex.value];
});

const htmlSettings = ref<ImageSize | ImageHtmlSettings>(ImageSize["1600x1200"]);

onMounted(() => {
  window.eventBus.on(Events.openGallery, onOpen);
  window.eventBus.on(Events.keyEscape, close);
  window.eventBus.on(Events.keyArrowLeft, previous);
  window.eventBus.on(Events.keyArrowRight, next);
});

onUnmounted(() => {
  window.eventBus.off(Events.openGallery, onOpen);
  window.eventBus.off(Events.keyEscape, close);
  window.eventBus.off(Events.keyArrowLeft, previous);
  window.eventBus.off(Events.keyArrowRight, next);
});

const onOpen = (settings: GalleryOpenSettings) => {
  media.value = settings.media;
  htmlSettings.value = settings.imageHtmlSettings ?? ImageSize["1600x1200"];
  currentIndex.value = settings.index ?? 0;
  isOpen.value = true;
  disableBodyScroll();
};

const next = () => {
  if (currentIndex.value + 1 === media.value.length) {
    currentIndex.value = 0;
  } else {
    currentIndex.value++;
  }
};

const previous = () => {
  if (currentIndex.value === 0) {
    currentIndex.value = media.value.length - 1;
  } else {
    currentIndex.value--;
  }
};

const goToImage = (i: number) => {
  currentIndex.value = i;
};

const close = () => {
  isOpen.value = false;
  allowBodyScroll();
};

const createImageHtml = (medium: GalleryMedium, size: ImageSize): string => {
  if (typeof medium === "string") {
    return '<img src="' + (medium as string) + '" />';
  } else {
    return medium.generateHtml(size);
  }
};
</script>

<style lang="scss" scoped>
.gallery {
  --icon-size: 30px;
  --horizontal-padding: 25px;
  --vertical-padding: 25px;
  --gap: 20px;
  --previews-size: 100px;

  z-index: 9001;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--vertical-padding) var(--horizontal-padding);

  &__content {
    width: 100%;
    max-width: 1230px;
    height: 100%;
    display: grid;
    grid-template-columns: var(--icon-size) auto var(--icon-size);
    grid-template-rows: auto var(--icon-size);
    grid-template-areas: "arrowLeft image arrowRight" "empty1 counter empty2";
    gap: var(--gap);
    position: relative;

    @include for-size(md) {
      grid-template-areas: "image image image" "arrowLeft counter arrowRight";
    }

    &--with-previews {
      @media (max-width: 1530px) {
        // image previews on bottom
        padding-bottom: calc(var(--vertical-padding) + var(--previews-size) * var(--item-image-ratio));
        .image-wrapper {
          max-height: calc(100vh - 2 * var(--vertical-padding) - var(--icon-size) - 3 * var(--gap) - var(--previews-size) * var(--item-image-ratio)) !important;
        }
      }
    }
  }
  .arrow {
    @include galleryControl();
    & {
      position: relative;
      top: 50%;
    }
    &--previous {
      grid-area: arrowLeft;
    }
    &--next {
      grid-area: arrowRight;
    }

    @include for-size(md) {
      top: 0;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .action {
    position: relative;
    @include galleryControl();
  }

  .counter-wrapper {
    grid-area: counter;
  }

  .image-wrapper {
    grid-area: image;
    max-height: calc(100vh - 2 * var(--vertical-padding) - var(--icon-size) - var(--gap));
    display: flex;
    align-items: center;
    justify-content: center;

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      aspect-ratio: var(--item-image-ratio);
    }
    &:deep(picture) {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img,
    &:deep(img) {
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 10px;
    }
  }

  .images-list {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 9px;
    height: 100vh;
    width: calc(var(--previews-size) + 2 * var(--gap));
    padding: var(--gap);
    overflow: auto;
    &__image-preview {
      flex-shrink: 0;
      aspect-ratio: var(--item-image-ratio);
      width: 100%;
      border-radius: var(--small-radius);
      border: 1px solid var(--gray);
      img,
      &:deep(img) {
        aspect-ratio: var(--item-image-ratio);
        border-radius: var(--small-radius);
        object-fit: contain;
        width: 100%;
        height: 100%;
        display: block;
      }

      &:hover,
      &.current {
        cursor: pointer;
        border-color: var(--primary-color);
      }
    }

    @media (max-width: 1530px) {
      top: auto;
      bottom: 0;
      flex-direction: row;
      justify-content: center;
      width: 100vw;
      height: calc(var(--previews-size) + 2 * var(--gap));
      &__image-preview {
        width: auto;
        height: 100%;
      }
    }
  }
}
</style>
