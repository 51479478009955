import { requireUser } from "../user";
import { Response401Exception } from "./apiExceptions";

/** Helper for filtering out 401 exception -> do not let it bubble further. */
export function catch401(e: unknown): void {
  if (e instanceof Response401Exception) {
    return;
  } else {
    throw e;
  }
}

/** For old-style-api URLs (token is in URL, not in headers). */
export function addTokenToUrl(relativeApiUrl: string): string {
  const user = requireUser();
  return user.getToken() + "/" + relativeApiUrl;
}
