import { RawWatchParameterData } from "@/types";
import { WatchParameter } from "./WatchParameter";

export function createWatchParameter(data: RawWatchParameterData): WatchParameter {
  const param = new WatchParameter(data.id, data.label);

  if (data.options) {
    param.setOptions(data.options);
  }

  if (data.allowCustomValues === 1) {
    param.setAllowCustomValues(true);
  }

  return param;
}
