import { User } from "@/services/user";

interface state {
  user: User | undefined;
}

export default {
  state: {
    user: undefined,
  },
  mutations: {
    login(state: state, user: User) {
      state.user = user;
    },
    logout(state: state) {
      state.user = undefined;
    },
  },
  getters: {
    getUser: (state: state): User | undefined => {
      return state.user;
    },
  },
};
