export interface RawCurrencyListData {
  id: number;
  code: string;
  symbol: string;
  isPositionLeft: boolean;
  isSpaceSeparator: boolean;
  decimalPrecisionWhenCurrencySwitch: number;
  decimalSeparator: string;
  thousandsSeparator: string;
  isAvailableForSwitchSelector: boolean;
  ratioThousands: { [key: number]: number };
}

export enum CurrencySymbolPosition {
  left,
  right,
}
