export default {
  state: {
    isPhone: false,
  },
  mutations: {
    saveIsPhone(state, value) {
      state.isPhone = value;
    },
  },
  getters: {
    getIsPhone: (state) => {
      return state.isPhone;
    },
  },
};
