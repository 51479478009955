export default {
  form: {
    phonePatternExplanation: "Vyplň telefonní číslo ve tvaru +420123456789.",
    wrongEmailFormat: "Vyplň existující e-mailovou adresu.",
    wrongUrlFormat: "Vyplň validní URL.",
    wrongUrlDomain: "Zadaná URL nemá požadovanou doménu.",
    wrongPasswordFormat: "Heslo musí obsahovat malé písmeno, velké písmeno, číslici a alespoň { minLength } znaků.",
    required: "Povinné",
  },
};
