import { CatalogueItem } from "../database/CatalogueItem";
import { LegoTheme } from "./LegoTheme";

export class LegoSet extends CatalogueItem {
  public partsCount: number | null = null;
  public dimensions: string | null = null;
  public year: number;
  public weight: number | null = null;
  public theme: LegoTheme | null = null;

  constructor(protected id: number, protected name: string, private number: string) {
    super(id);
  }

  public getNumber(): string {
    return this.number;
  }

  public getIdentification(): string {
    return this.getNumber();
  }
}
