export default {
  termsAndConditions: {
    heading: "Zásady ochrany osobních údajů",
    content: `
      <p>Pokud zpracováváme Vaše osobní údaje, tak vždy postupujeme v souladu s Nařízením (EU) 2016/679, o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (dále jen „GDPR“) a dalšími souvisejícími právními předpisy.</p>

      <h2>Správce</h2>
      <ul>
        <li>Správcem Vašich osobních údajů je společnost {companyName}, IČO: {companyTaxId}, sídlem {companyStreet} {companyBuilding}, {companyDistrict}, {companyZip} {companyCity} (dále jen „Společnost“).</li>
      </ul>

      <h2>Informace pro odběratele newsletterů</h2>
      <ul>
        <li>Vaše osobní údaje budeme na základě Vámi poskytnutého souhlasu zpracovávat pouze za účelem zasílání marketingových sdělení, zejména newsletterů nebo Vás budeme informovat o dění v oblastech, jichž se naše činnost týká.</li>
        <li>Osobním údajem zpracovávaným pro účel uvedený v předchozím bodě je Vaše e-mailová adresa, kterou jste nám na základě Vašeho souhlasu sami poskytli a na kterou Vám budeme výše uvedená sdělení zasílat.</li>
        <li>Vaši e-mailovou adresu budeme zpracovávat po dobu trvání Vašeho souhlasu. Poskytnutí Vašeho souhlasu je zcela dobrovolné a můžete jej tak kdykoliv jednoduše odvolat zasláním požadavku na níže uvedenou kontaktní adresu nebo proklikem, který zpravidla najdete v zaslaném marketingové sdělení.</li>
        <li>K Vaší e-mailové adrese budou mít přístup pouze pověření zaměstnanci Společnosti, kteří jsou vázáni mlčenlivostí a poučeni o závazcích plynoucích z ochrany osobních údajů.</li>
      </ul>

      <h2>Uživatelský účet</h2>
      <ul>
        <li>Vaše osobní údaje budeme zpracovávat též na základě registrace Vašeho uživatelského účtu a přihlášením do služby na našich webových stránkách.</li>
        <li>Osobním údajem zpracovávaným pro tento účel je Vaše e-mailová adresa, pod kterou jste se registrovali a pod kterou se přihlašujete. V nastavení můžete rovněž nahrát profilový obrázek a uvést Vaše jméno, příjmení, adresu, telefonní číslo, URL vašich sociálních sítí, datum narození a pohlaví, aby byl Váš profil kompletní.</li>
        <li>Pro vytvoření zákaznického účtu a následného přihlašování k Vašemu účtu můžete využít též přístupu z Vašeho účtu na Google nebo z&nbsp;platforem společnosti Meta (tj. ze sociální sítě Facebook či Instagram). V takovém případě nebudete muset Vaše údaje vyplňovat ručně a my od společnosti Meta nebo Google získáme Vaše osobní údaje v&nbsp;takovém rozsahu, v&nbsp;jakém poskytnete svůj souhlas, minimálně však e-mailovou adresu, která je nezbytná pro vytvoření Vašeho uživatelského účtu.</li>
        <li>Osobní údaje dle tohoto článku budeme zpracovávat po dobu trvání vaší registrace na našich webových stránkách v&nbsp;souladu s&nbsp;čl. 6 odst. 1 písm. b) GDPR.</li>
        <li>Váš profil bude veřejný. Pokud však budete chtít, aby byl Váš profil pouze soukromý, můžete toto změnit v&nbsp;nastavení svého uživatelského účtu.</li>
      </ul>

      <h2>Tvoření sbírek</h2>
      <ul>
        <li>V&nbsp;rámci Vašeho profilu máte možnost udržovat a spravovat svou sbírku sběratelských předmětů online, a to přidáváním sběratelských předmětů prostřednictvím naší databáze, ukládáním dat a nahráváním vlastních obrázků těchto sběratelských předmětů. Přitom evidujeme následující údaje:
          <ul>
            <li>Parametry a hodnoty, typ, značka, model, počet kusů, kategorie a stav sběratelského předmětu.</li>
          </ul>
        </li>
        <li>Můžete také zadat nákupní cenu, čas a místo nákupu a nahrát obrázky svých sběratelských předmětů. Jedná se však o dobrovolné informace.</li>
        <li>Uvedené osobní údaje budeme zpracovávat pro následující účely:
          <ul>
            <li>pro dokumentaci a odhad hodnoty vaší osobní sbírky,</li>
            <li>pro zaznamenání vašeho zájmu o jednotlivé sběratelské předměty, které ještě nevlastníte,</li>
            <li>pro statistické vyhodnocení Vašich sběratelských předmětů a</li>
            <li>pro rozšiřování našeho produktového katalogu o sběratelské předměty, které jsme dosud neznali.</li>
          </ul>
        </li>
        <li>U námi shromažďovaných údajů se nejedná o osobní údaje, ale pouze o údaje vztahující se k osobám. Osobní údaje jsou údaje bez přímé osobní reference, ze kterých lze odvodit osobu.</li>
        <li>Při zpracování osobních údajů dle tohoto článku vycházíme z čl. 6 odst. 1 písm. b) GDPR a našeho oprávněného zájmu v souladu s čl. 6 odst. 1 písm. f) GDPR.</li>
        <li>Oprávněný zájem zde spočívá ve využívání naší databáze sběratelských předmětů jako zdroje informací, ve kterém můžeme statistickým vyhodnocením dat rozšířit naše know-how o aktuální situaci na trhu a do budoucna dle potřeby optimalizovat naše služby.</li>
        <li>Vaše sbírka sběratelských předmětů je veřejná spolu s&nbsp;Vaším profilem. Pokud si přejete mít sbírku jen v&nbsp;soukromém režimu, můžete tak jednoduše učinit a změnit nastavení v&nbsp;rámci svého uživatelského účtu.</li>
      </ul>

      <h2>Využívání zpráv</h2>
      <ul>
        <li>Jako registrovanému uživateli Vám nabízíme možnost komunikovat s námi a jiným uživatelem prostřednictvím zpráv na našem webu.</li>
        <li>Komunikace prostřednictvím zpráv vždy probíhá mezi Vámi, Vaším komunikačním partnerem a námi. V rámci používání našich interních zpráv Vaše odeslané zprávy automaticky a ručně skenujeme a analyzujeme. To provádíme za účelem
          <ul>
            <li>prevence podvodů,</li>
            <li>odhalování nezákonných činností a porušování našich zásad fungování a</li>
            <li>zlepšování komunikace a péče o zákazníky.</li>
          </ul>
        </li>
        <li>Zpracování osobních údajů dle tohoto článku se zakládá na našich oprávněných zájmech podle čl. 6 odst.1 písm. f) GDPR. Zpracování osobních údajů pro výše uvedené účely platí podle GDPR jako předem uznaný oprávněný zájem.</li>
        <li>Své zaslané a přijaté zprávy můžete spravovat sami a na požádání je nechat námi smazat. V případě pokusu o podvod nebo protiprávního jednání můžeme příslušné zprávy na základě našich oprávněných zájmů podle čl. 6 odst.1 písm. f) GDPR i přes Vaši žádost o smazání nadále uchovávat pro důkazní účely a uplatnění, výkon nebo obhajobu zákonných nároků.</li>
      </ul>

      <h2>O souborech cookies</h2>
      <ul>
        <li>Naše webové stránky využívají soubory cookies. Cookies jsou malé soubory uložené ve Vašem počítači nebo mobilním zařízení webovými stránkami. Soubory cookies umožňují webovým stránkám zajišťovat základní funkce webu, ukládat nastavení stránek, analyzovat provoz na stránkách, shromažďovat informace při prohlížení (včetně Vaší IP adresy) a poskytovat cílený obsah a marketingovou komunikaci.</li>
        <li>Na našich webových stránkách používáme následující typy cookies:
          <ul>
            <li>Technické cookies jsou ty, které musíme na Vaše zařízení uložit, aby naše stránky mohly správně fungovat, z tohoto důvodu se nevyžaduje Váš souhlas.</li>
            <li>Statistické cookies využíváme k optimalizaci webových stránek pro naše uživatele, díky kterým získáváme přehled o používání našich webových stránek. K používání tohoto typu cookies od Vás žádáme souhlas.</li>
            <li>Marketingové cookies slouží ke shromažďování údajů o tom, jak používáte web za účelem poskytování relevantních marketingových sdělení různými marketingovými kanály. I k používání těchto cookies žádáme o Váš souhlas.</li>
          </ul>
        </li>
        <li>Jaké cookies používáme? <br />
          <table>
            <tr>
              <th>Název</th>
              <th>Typ</th>
              <th>Kdo ji využívá</th>
              <th>Expirace</th>
              <th>Účel</th>
            </tr>
            <tr>
              <td>_ga</td>
              <td>Analytické</td>
              <td>Google Analytics</td>
              <td>2 roky</td>
              <td>Používají se na podporu optimalizace internetových stránek tím, že umožňují shromažďovat obecné statistické informace o využívání stránek.</td>
            </tr>
            <tr>
              <td>_gid</td>
              <td>Analytické</td>
              <td>Google Analytics</td>
              <td>1 den</td>
              <td>Používají se na podporu optimalizace internetových stránek tím, že umožňují shromažďovat obecné statistické informace o využívání stránek.</td>
            </tr>
            <tr>
              <td>_utm.gif</td>
              <td>Analytické</td>
              <td>Google Analytics</td>
              <td>Při zavření prohlížeče</td>
              <td>Používají se na podporu optimalizace internetových stránek tím, že umožňují shromažďovat obecné statistické informace o využívání stránek.</td>
            </tr>
            <tr>
              <td>dsr_swli</td>
              <td>Technické</td>
              <td>{domain}</td>
              <td>1 rok</td>
              <td>Vylepšení uživatelského zážitku během přihlašovacího procesu.</td>
            </tr>
          </table><br />
        </li>
        <li>Nastavení souborů cookies máte pod Vaší kontrolou. Můžete se rozhodnout, zda povolit nebo zakázat různé typy souborů cookies na Vašem zařízení. Pokud se rozhodnete změnit uložené nastavení těchto souborů, můžete jej kdykoliv změnit pomocí tlačítka "Nastavení cookies" umístěného v patičce. Cookies též můžete vymazat nebo zablokovat přímo ve Vašem prohlížeči. V takovém případě pak nemusí naše stránky fungovat zcela správně.</li>
      </ul>

      <h2>Vaše práva související se zpracováním osobních údajů</h2>
      <ul>
        <li>Pokud jde o Vaše údaje máte následující práva:
          <ul>
            <li>Právo na informace, zejména máte právo vědět zda, proč a jakým způsobem zpracováváme Vaše osobní údaje.</li>
            <li>Právo na přístup k Vašim osobním údajům, které jsou nám známy.</li>
            <li>Právo na opravu, doplnění v případě, že zjistíte, že o Vás zpracováváme nepřesné nebo nesprávné osobní údaje.</li>
            <li>Právo na výmaz, pokud jsou osobní údaje již nepotřebné, odvoláte Vámi udělený souhlas, vznesete námitky proti zpracování, osobní údaje zpracováváme protiprávně či musí být osobní údaje vymazány ke splnění právní povinnosti.</li>
            <li>Právo na omezení zpracování Vašich osobních údajů, kdy na Vaši žádost můžeme za určitých právními předpisy stanovených podmínek omezit nakládání s Vašimi osobními údaji.</li>
            <li>Právo na přenositelnost Vašich osobních údajů k jinému správci.</li>
            <li>Právo na námitku proti zpracování osobních údajů, pokud jsou osobní údaje zpracovávány pro naše oprávněné zájmy.</li>
          </ul>
        </li>
        <li>Pro uplatnění práv uvedených v předchozím bodě nás prosím kontaktujte na níže uvedenou adresu. Máte-li výhrady proti tomu, jak nakládáme s Vašimi osobními údaji, rádi bychom se o tomto dozvěděli. Máte však právo podat stížnost u dozorového úřadu, tedy u Úřadu pro ochranu osobních údajů, nebo se domáhat soudní ochrany.</li>
      </ul>

      <h2>Kontaktní údaje</h2>
      <p>
        {companyName}<br />
        {companyStreet} {companyBuilding}, {companyZip} {companyCity}<br />
        {domain}<br />
        {email}
      </p>
    `,
  },
};
