import { Currency } from "./Currency";
import { CurrencySymbolPosition, RawCurrencyListData } from "./currencyTypes";

const SYMBOL_POSITION_LEFT_VALUE = true;

export function createCurrency(data: RawCurrencyListData): Currency {
  const currency = new Currency(data.id);
  currency.setCode(data.code);
  currency.setDecimalSeparator(data.decimalSeparator);
  currency.setDecimalPrecision(data.decimalPrecisionWhenCurrencySwitch);
  currency.setIsAvailableForSwitchSelector(data.isAvailableForSwitchSelector);
  currency.setIsSpaceSeparator(data.isSpaceSeparator);
  currency.setSymbol(data.symbol);
  currency.setSymbolPosition(data.isPositionLeft === SYMBOL_POSITION_LEFT_VALUE ? CurrencySymbolPosition.left : CurrencySymbolPosition.right);
  currency.setThousandsSeparator(data.thousandsSeparator);

  for (const code in data.ratioThousands) {
    currency.addRatio(parseInt(code), data.ratioThousands[code]);
  }

  return currency;
}
