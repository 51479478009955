export default {
  userSettings: {
    heading: "Nastavení profilu",
    imageEditBtn: "Upravit foto",
    avatarUploadInProgress: "Nahrávám...",
    avatarUploadFailed: "Obrázek se nepodařilo nahrát.",
    headings: {
      personalData: "Osobní údaje",
      links: "Odkazy",
      bio: "Bio",
      notifications: "E-mailové upozornění",
      credentials: "Přihlašovací údaje",
      privacy: "Nastavení soukromí",
    },
    fieldLabels: {
      publicNickname: "Přezdívka",
      publicName: "Jméno",
      publicEmail: "E-mail",
      publicPhone: "Telefon",
      publicUrlFacebook: "Facebook",
      publicUrlInstagram: "Instagram",
      publicUrlTikTok: "TikTok",
      publicUrlPinterest: "Pinterest",
      publicUrlWeb: "Web",
      chatNewMessagesNotifications: "Na nové zprávy",
      email: "E-mail",
      password: "Heslo",
      currentPassword: "Současné heslo",
      newPassword: "Nové heslo",
      newPasswordAgain: "Heslo znovu",
      isPublicProfile: "Veřejný profil",
      areItemsPublicByDefault: "Zveřejňovat nové předměty ",
    },
    messagesSaved: {
      emailNotificationEnabled: "E-mailové upozornění zapnuto.",
      emailNotificationDisabled: "E-mailové upozornění vypnuto.",
    },
    changePasswordButton: "Změnit heslo",
    savePasswordButton: "Uložit",
    passwordChanged: "Gratulujeme! Změnil(a) sis heslo.",
    nicknameTooltip: "Další možná editace za { days } dní.",
    errors: {
      publicNameTooLong: "Jméno nesmí být delší než { maxLength } znaků.",
      nicknameWrongFormat: "Přezdívka smí obsahovat pouze alfanumerické znaky (bez diakritiky) a pomlčku.",
      nicknameWrongLength: "Přezdívka musí mít délku { minLength }-{ maxLength } znaků.",
      bioTooLong: "Délka bia je omezena na { maxLength } znaků.",
      currentPasswordIncorrect: "Zadané současné heslo nesedí. Zkus to znovu.",
      emptyCurrentPassword: "Vyplň současné heslo.",
      passwordsMismatch: "Hesla se bohužel neshodují.",
      badRequest: "Nastavení se nepodařilo uložit. Zasíláte správná data?",
      notFound: "Tohoto uživatele neznáme.",
      nicknameTaken: "Bohužel, přezdívku už používá někdo jiný. Zkus nějakou jinou.",
      nicknameTooEarlyChange: "Přezdívku zatím nelze změnit.",
    },
    visibilityPopup: {
      header: "Profil",
      onMakePublic: "Chystáš se zveřejnit si profil. Uživatelé uvidí tvé předměty, které sis zakliknul jako veřejné. Jsi si jistý?",
      onMakePrivate: "Chystáš se odveřejnit svůj profil. Tvá sbírka bude před ostatními uživateli skryta. Jsi si jistý?",
      confirmPublic: "Zveřejnit",
      confirmPrivate: "Odzveřejnit",
      cancel: "Ponechat",
    },
    tooltipPrivacySettings: {
      p1: "Nastav si své soukromí sbírky. Pokud nastavíš Profil jako veřejný, můžeš využívat svoji unikátní URL a sdílet profil s ostatními uživateli. Pokud ho nastavíš jako Soukromý, nikdo tvůj profil neuvidí.",
      p2: "Pokud nastavíš přepínač Nově přidaný předmět jako veřejné, při přidávání předmětu budou předměty automaticky zveřejňované (zobrazí se ve veřejném profilu). I tak budeš mít možnost před uložením předmětu předmět nezveřejňovat.).",
    },
    favouriteCategories: {
      title: "Oblíbené kategorie",
      noCategorySelected: "Nic nevybráno",
      placeholder: "Vyber kategorie",
      help: "Vyber si své oblíbené kategorie. Podle nich ti pak přizpůsobíme obsah.",
      dialogCancel: "Zrušit",
      dialogConfirm: "Uložit",
      dialogSaving: "Ukládám…",
      savedMsg: "Tvé oblíbené kategorie aktualizovány.",
      saveError: "Tvé oblíbené kategorie se nepodařilo aktualizovat. Zkus to prosím znova nebo nás kontaktuj.",
    },
  },
};
