export enum ImageSize {
  "85x64" = "85x64",
  "310x233" = "310x233",
  "467x350" = "467x350",
  "1200x900" = "1200x900",
  "1600x1200" = "1600x1200",
}

export type ImageUrls = { [key: string]: string };

export type ImageVariants = { [key in ImageSize]?: ImageUrls };

export interface RawImageData {
  id: number;
  imageUrl: ImageVariants;
}

export interface SingleImageData {
  extension: string;
  url: string;
}

// <picture> tag settings
export interface ImageHtmlSettings {
  sources: {
    media: string;
    size: ImageSize;
  }[];
  fallbackSize: ImageSize;
}
