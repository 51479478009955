export default {
  addItem: {
    heading: "Přidej si předmět",
    favouriteCategories: "Oblíbené kategorie",
    loadingCategories: "Načítám kategorie...",
    itemName: "Název předmětu / ID",
    itemVisibilityLabel: "Veřejný předmět",
    createItemButton: "Přidat do sbírky",
    creatingItem: "Ukládám předmět...",
  },
};
