import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { App, inject } from "vue";
import { Website } from "@/config";

enum GtmCookieCategory {
  Essential = "functionality_storage",
  Personalization = "personalization_storage",
  Analytics = "analytics_storage",
  Advertising = "ad_storage",
}

const PluginName = "CookieConsent";
const OurDomain = "." + Website.domain;
const UsedCookies: { [Key in GtmCookieCategory]: CookieConsent.CookieItem[] } = {
  [GtmCookieCategory.Essential]: [
    {
      name: "dsr_swli",
      domain: OurDomain,
    },
  ],
  [GtmCookieCategory.Analytics]: [
    {
      name: /.+/,
      domain: ".google.com",
    },
    {
      name: /.+/,
      domain: ".google.cz",
    },
    {
      name: /^_ga.*/,
      domain: OurDomain,
    },
    {
      name: /.+/,
      domain: ".doubleclick.net",
    },
    {
      name: /.+/,
      domain: ".tiktok.com",
    },
    {
      name: "_tt_enable_cookie",
      domain: OurDomain,
    },
    {
      name: "_ttp",
      domain: OurDomain,
    },
    {
      name: /^SL_C_.*/,
      domain: OurDomain,
    },
  ],
  [GtmCookieCategory.Advertising]: [],
  [GtmCookieCategory.Personalization]: [],
};

export const CookieConsentPlugin = {
  install: (app: App) => {
    const cc = CookieConsent;
    cc.run(CookieConsentConfig);
    app.provide(PluginName, cc);
  },
};

// components' composable
export const useCookieConsent = () => {
  const cc = inject(PluginName) as typeof CookieConsent;

  function openSettings() {
    cc.showPreferences();
  }

  return { openSettings };
};

const CookieConsentConfig: CookieConsent.CookieConsentConfig = {
  lazyHtmlGeneration: true,
  autoClearCookies: true,
  guiOptions: {
    consentModal: {
      layout: "box inline",
      position: "bottom right",
    },
  },
  categories: (() => {
    const categories: { [name: string]: CookieConsent.Category } = {};

    for (const [category, cookies] of Object.entries(UsedCookies)) {
      let categorySettings;
      if (category === GtmCookieCategory.Essential) {
        categorySettings = {
          readOnly: true,
          enabled: true,
        };
      } else {
        categorySettings = {
          autoClear: {
            cookies: cookies,
            reloadPage: false,
          },
        };
      }

      categories[category] = categorySettings;
    }

    return categories;
  })(),
  language: {
    default: "cs",

    translations: {
      cs: {
        consentModal: {
          title: "Využíváme cookies",
          description: "Váš souhlas se sušenkama vás možná nezasytí, ale pomůže nám dělat pro vás maximálně dobrou práci.",
          acceptAllBtn: "Přijmout vše",
          acceptNecessaryBtn: "Přijmout nezbytné",
          showPreferencesBtn: "Nastavení cookies",
          closeIconLabel: "Odmítnout vše",
        },
        preferencesModal: {
          title: "Nastavení cookies",
          acceptAllBtn: "Přijmout vše",
          acceptNecessaryBtn: "Odmítnout vše",
          savePreferencesBtn: "Uložit nastavení",
          sections: [
            {
              title: "Co jsou to cookies?",
              description: "Soubory cookies a další technologie nám pomáhají zlepšovat naše služby a pomáhají nám analyzovat výkon webu. V nastavení si můžete vybrat, které cookies můžeme používat. Svůj souhlas můžete kdykoliv odvolat.",
            },
            {
              title: "Nezbytné cookies pro správné fungování našeho webu.",
              description: "Tyto cookies jsou nezbytně nutné pro správnou funkci webu - zajišťují správné zobrazení, odesílání formulářů, vkládání zboží do košíku a podobně. Technické cookies není možné vypnout.",
              linkedCategory: GtmCookieCategory.Essential,
            },
            {
              title: "Sledování návštěvnosti a chování na webu nám pomáhá zlepšovat naše služby.",
              description: "Statistické cookies používáme k vyhodnocování výkonu našeho webu a reklamních kampaní. Pomocí cookies určujeme počet návštěv, zdroje návštěvnosti a chování zákazníků na našem webu - např. informace o tom, co na webu hledáte a které informace jsou pro vás nejdůležitější. Na základě těchto dat můžeme provádět optimalizaci webu a vylepšovat naše služby.",
              linkedCategory: GtmCookieCategory.Analytics,
            },
            {
              title: "Cookies pro cílení a reklamu",
              description: "Tyto soubory cookie se používají k zobrazování reklamy, která vás pravděpodobně bude zajímat na základě vašich zvyků při procházení. Tyto soubory cookie, jsou požadovány námi/nebo poskytovateli reklam, mohou kombinovat informace shromážděné z našich webových stránek s dalšími informacemi, které nezávisle shromáždily z jiných webových stránek, týkající se činností vašeho internetového prohlížeče v rámci jejich reklamní sítě webových stránek.",
              linkedCategory: GtmCookieCategory.Advertising,
            },
            {
              title: "Personalizace obsahu",
              description: "Tyto cookies nám umožní zobrazit Vám obsah dle údajů, které jsou o Vás dostupné tak, abychom se co nejlépe strefili do Vašich potřeb. Jde zejména o to, jaký obsah jste si zobrazili, či na jakém zařízení na naše stránky přicházíte.",
              linkedCategory: GtmCookieCategory.Personalization,
            },
          ],
        },
      },
    },
  },
};
