import { Category } from "@/services/repositories/collection/Collection";
import { CatalogueFilter } from "@/services/repositories/database/catalogueItemsFilter";

interface state {
  filters: CatalogueFilter[];
}

export default {
  state: {
    filters: [],
  },
  mutations: {
    saveCatalogueFilter(state: state, filter: CatalogueFilter) {
      const categoryId = filter.category.getId();
      const found = state.filters.find((foundFilter) => foundFilter.category.getId() === categoryId);

      if (!found) {
        state.filters.push(filter);
      }
    },
  },
  getters: {
    getCatalogueFilter: (state: state) => (category: Category) => {
      return state.filters.find((foundFilter) => foundFilter.category.getId() === category.getId());
    },
  },
};
