export default {
  catalogue: {
    itemBox: {
      id: "ID",
      price: "Cena",
    },
    filter: {
      boardGames: {
        name: "Název",
        developers: "Autoři",
        languages: "Jazyk",
      },
    },
    table: {
      boardGames: {
        developer: "Autor",
        language: "Jazyk",
        year: "Rok vydání",
      },
    },
  },
};
